import axios from "axios";
import { API, ROLES } from "../utils/constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import CalendarIcon from "../../resources/images/calendar-icon.png";
const keys = {
  listOfNewHosts: ["listOfNewHosts"],
  getGridGiftSwitchCalendar: true,
};

const getListOfNewHosts = async (idUser) => {
  const { data } = await axios.get(
    `${API.LIST_OF_NEW_HOSTS.url}/${idUser}?action=LIST_OF_NEW_HOSTS`
  );
  return data.data;
};

export const getGridGiftSwitch = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const response = data?.map((item) => {
    return {
      type: "giftSwitch",
      props: {
        role: item.role,
        nameRole: ROLES[item.role].roleName,
        name: item.name,
        range: 7,
        id: item?._id,
      },
      action: "putAddToWhatSapp",
    };
  });
  return response;
};

export const getGridGiftSwitchCalendar = async (idUser) => {
  const data = await getListOfNewHosts(idUser);

  const rowInfo = data?.map((item) => {
    const isRegistered = item.personalReferences?.processStatus === 'registered';

    return {
      type: 'paragraph',
      props: {
        size: 's',
        align: 'center',
        style: {
          width: '129px',
          top: '-10px',
          opacity: '0.7',
        },
      },
      children: isRegistered
        ? 'Ya te agendaste al Epa.'
        : 'Aún no te has agendado al Epa.',
    };
  });
  const rowCard = data?.map((item, index) => {
    const isRegistered = item.personalReferences?.processStatus === 'registered';

    return {
      type: 'giftSwitch',
        props: {
        role: item.role === 'host' ? 'host' : item.role,
          nameRole: item.role === 'host' ? 'Anfitrión(a)' : ROLES[item.role]?.roleName,
            name: item.name,
              range: 7,
                items: [
                  {
                    image: CalendarIcon,
                    title: 'Prueba', // Aquí puedes cambiar el título según sea necesario
                    wait: !isRegistered, // Estado de espera si no está registrado
                    width: '24px',
                  },
                ],
        },
    };
  });

  const response = [...rowCard, ...rowInfo];
  return response;
};


const textFieldValues = {};

const setTextFieldValue = (hostId, fieldIndex, value) => {
  if (!textFieldValues[hostId]) {
    textFieldValues[hostId] = [];
  }
  textFieldValues[hostId][fieldIndex] = value;
};

const handleSubmitPersonalReferences = (hostId, idChallenge) => {
  const values = textFieldValues[hostId] || [];
  const data = {
    references: Object.values(values).map((value) => ({ name: value })),
  };

  axios
    .put(`${API.SAVE_PERSONAL_REFERENCES.url}/${hostId}/${idChallenge}`, data)
    .then((response) => { })
    .catch((error) => {
      console.log(error);
    });
  delete textFieldValues[hostId];
};

export const getGridGiftSwitchChallengeInductionC = async (
  idUser,
  idChallenge
) => {
  const data = await getListOfNewHosts(idUser);
  const result = [];

  data.forEach((host, index) => {
    const candidatesScheduled = host.candidates.filter(
      (c) =>
        c.paymentLevelsAcdcPlatform === "APPROVED" &&
        c.paymentForAudiovisualContentAcdc === "APPROVED"
    ).length;

    const step = (index + 1) * 2;

    // Paso principal para mostrar información del anfitrión
    result.push({
      type: "step",
      props: { order: step },
      bricks: [
        {
          type: "menuUserInfo",
          props: {
            name: `${host.name} -> ${candidatesScheduled}`,
            role: host.role,
            nameRole: "Anfitrión",
            range: candidatesScheduled,
            style: { marginTop: "24px" },
          },
        },
        {
          type: "paragraph",
          props: { size: "s", align: "center", style: { marginTop: "34px" } },
          children: `Avance Meta 1: ${candidatesScheduled}/6 Agendados`,
        },
        {
          type: "counterBadges",
          props: {
            items: Array(6)
              .fill(0)
              .map((_, index) => ({ isActive: index < candidatesScheduled })),
          },
        },
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            withBackground: true,
            style: { marginTop: "24px" },
          },
          children:
            "Recomiéndales personas que tanto tú como tu Anfitrión(a) conozcan y que creas tienen buen perfil para ser parte de nuestra comunidad épica.",
        },
        {
          type: "button",
          props: {
            styles: { position: "relative", marginTop: "96px" },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    });

    // Paso con los campos de texto y el botón de envío
    result.push({
      type: "step",
      props: { order: step + 1 },
      bricks: [
        {
          type: "paragraph",
          props: { size: "xs", align: "center" },
          children: `Se habilitaron ${candidatesScheduled} Validadores recomendados para ${candidatesScheduled} cupos restantes del(la) Anfitrión(a):`,
        },
        {
          type: "textFieldList",
          bricks: Array(candidatesScheduled)
            .fill(0)
            .map((_, fieldIndex) => ({
              type: "textField",
              props: {
                onChange: (e) =>
                  setTextFieldValue(host._id, fieldIndex, e.target.value),
              },
            })),
        },
        {
          type: "button",
          props: {
            styles: { position: "relative", marginTop: "96px" },
            onClick: () =>
              handleSubmitPersonalReferences(host._id, idChallenge), // Usa la función de envío
          },
          children: "Continuar",
          action: "handleSubmitPersonalReferences",
        },
      ],
    });
  });

  return result;
};

export const QueriesListOfNewHosts = (idUser) => {
  return useQuery({
    queryKey: keys.listOfNewHosts,
    queryFn: async () => await getListOfNewHosts(idUser),
  });
};

const putAddToWhatSapp = async (data) => {
  let dataUpdate = {
    status: data.status,
    property: "addedToWhatsappGroup",
  };

  const resp = await axios.put(
    `${API.ADD_TO_WHATSAPP_GROUP.url}/${data.id}?action=update-property-host`,
    dataUpdate
  );
  return resp;
};

export const QueriesputAddToWhatSapp = () => {
  const mutation = useMutation({
    mutationFn: (data) => putAddToWhatSapp(data),
    onSuccess: (response) => {
      console.log(response);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return mutation;
};
