import React from 'react'
import { API } from '../utils/constants';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const keys = {
    getInformation: ["getInformation"],
  };
  
const getInformation = async (idUser) => {
    const { data } = await axios.get(`${API.GET_INFORMATION.url}/${idUser}?action=GET_INFORMATION`);
    return data.data;
}
  

export const QueriesGetInformation = (idUser) => {
    return useQuery({
      queryKey: keys.getInformation,
      queryFn: async () => await getInformation(idUser),
    })
  }
  