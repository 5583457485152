import SyncCoordinator from "../../resources/images/sync-coordinator.png";
import GoodHandCoordinator from "../../resources/images/good-hand-coordinator.png";
import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";

export const getBricksChallengeA = (id) => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: SyncCoordinator,
                    alt: 'sync-coordinator',
                    style: {
                        marginTop: '39px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: 'Hoy en tu Inducción como Coordinador(a)<br/>tu principal tarea es sincronizar a tus dos Anfitriones, quienes deben ver el video de Inducción a la hora agendada y completar el Desafío A.<br/><br/>Una vez logrado, podrán avanzar juntos hacia el Desafío B, que es el Entrenamiento ABC de la Convocatoria.',
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: 'Confirma si tus dos Anfitriones están sincronizados contigo en este momento. Ingresa al grupo de WhatsApp del equipo ACDC y verifica que tus dos Anfitriones han enviado la foto de pantalla de la primera pausa del video Inducción Anfitrión.',
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    withBackground: true,
                    style: {
                        marginTop: '24px',
                    }
                },
                children: 'A continuación debes activar al Anfitrión que ya se encuentre sincronizado contigo.'
            },
            {
                type: 'gridGiftSwitch',
                bricks: 'getGridGiftSwitch'
            },
            {
                id: 'continuePutAddToWhatSapp',
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                    disabled: true,
                },
                children: 'Continuar',
                action: 'next',
                counter: 0,
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Vas súper!<br/><br/>Te queda poco para completar el Desafío A.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '39px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                },
                children: 'Ahora que ya realizaste las actividades, continúa con el siguiente ▶️ VIDEO, para seguir con tu Inducción.<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Ver video',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío A.'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                },
                children: 'Ahora que ya realizaste las actividades, continúa con el siguiente ▶️ VIDEO, para seguir con tu Inducción.<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Ver video',
                action: 'finish',
            }
        ]
    }
];