import Paragraph from "../../atoms/paragraph";


const ActivitySelectedCoordinator = ({ applicantsAndHosts,title,descriptionOne,descriptionTwo, className,icon }) => {


  return (
    <>
      <span className="title"><b>{title}</b></span>
      <Paragraph size="s" align="center" className="p-room-p-1">
       {descriptionOne}
      </Paragraph>
      <Paragraph size="s" align="center" className="p-room-p-1">
      {descriptionTwo}
      </Paragraph>

      {applicantsAndHosts?.map((generation) => <div className="generation-map">
       <br/>
        <Paragraph size="s" align="center" className={`${className}`}>
        <br/>
          <div  >
            <div className={`info host`} >
              <span className="role"> {icon} </span>
              <span className="name"><b>{generation.name}:</b></span>
            </div>
          </div>
          <br/>
        </Paragraph>
        <div className="epa-requirements__status">
          {
            generation.candidates?.map((candidate) => candidate.role == "candidate" && (
              <>
                <span className={`medal-switch `}>
                  <span className="medal-switch__info__name">{candidate.name}</span>
                </span>
              </>
            ))
          }
        </div>
      </div>)}
    </>
  )
}

export default ActivitySelectedCoordinator;