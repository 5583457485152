import React from 'react'

import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'
import Book from "../../../resources/images/book.png";
import TableNewDirectorAngry from "../../../resources/images/table_new_director_angry.png";
import './index.scss';

export const ChallengeHonorSecond = ({ setStep, step }) => {


  return (
    <>
    <br/>
    <div>
                    <img src={TableNewDirectorAngry} />
                    <Paragraph align="left" className="text-hungry" size="s">
                    “Las palabras de un líder y los pasos que da, deben coincidir. Como dice el dicho: “Tu palabra es tu valor y tu valor está en tu palabra.”
                    </Paragraph>
                </div>

                <div className="phrase-container">
                    <img src={Book} alt="Book" className="book" />
                    <Paragraph size="s" align="left" className="gift-text-phrase ">
                        Fragmento tomado del Libro
                        <br />
                        <b>Lanzando una Revolución sobre el Liderazgo.</b>
                    </Paragraph>
                </div>
 
   
      <br/>
      <br/>
      <br/>
      <br/>
      <Button onClick={() => setStep(step + 1)}>Continuar</Button>


    </>
  )
}
