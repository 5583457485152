import axios from "axios";
import { API } from "../utils/constants";


const transformCaptainAndHostWithAspirants = (list) => {
    let transformedList = [];
    list?.forEach(item => {
        const { children } = item;
        transformedList.push({
            ...item,
            disabled: item.role === 'candidate' || item.role === 'coordinator',
        });
        const childList = transformCaptainAndHostWithAspirants(children);
        transformedList = [
            ...transformedList,
            ...childList
        ]
    });
    return transformedList;
};

const getCaptainAndHostWithAspirants = async (userId) => {
    try {
        const list = await axios.get(`${API.GET_CAPTAIN_AND_HOST_WITH_APPLICANTS.url}/${userId}?action=GET_CAPTAIN_AND_HOST_WITH_APPLICANTS`);

        return transformCaptainAndHostWithAspirants(list.data.data);
    } catch (error) {
        console.log(error);
    }
};

const getListOfHostWithTheirSelectApplicants = async (userId) => {
    try {
        const list = await axios.get(`${API.LIST_OF_HOSTS_WITH_THEIR_SELECTED_APPLICANTES.url}/${userId}?action=LIST_OF_HOSTS_WITH_THEIR_SELECTED_APPLICANTES`);
       return transformCaptainAndHostWithAspirants(list.data.data);
    } catch (error) {
        console.log(error);
    }
};


const getDirectorAndCoordinatorWithHostTheIrApplicants = async (userId) => {
    try {
        const {data} = await axios.get(`${API.GET_CAPTAIN_AND_HOST_WITH_APPLICANTS.url}/${userId}?action=GET_CAPTAIN_AND_HOST_WITH_APPLICANTS`);
        return transformCaptainAndHostWithAspirants(data.data);
    } catch (error) {
        console.log(error);
    }
};


const postAwardindMedalsToTheRooms = async (userId, idChallenge) => {
    try {
        const data =  {
            action: 'AWARDING_MEDALS_TO_THE_ROOMS',
        };
        const response = await axios.post(`${API.AWARDING_MEDALS_TO_THE_ROOMS.url}/${userId}/${idChallenge}`, data);
        if (response.status === 201) {
            return response.data;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getCandidates = async (userId) => {
    try {
        const response = await axios.get(`${API.GET_CANDIDATES_FOR_THE_COORDINATOR.url}/${userId}?action=GET_CANDIDATES_FOR_THE_COORDINATOR`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};

const getGeneralUserTable = async (userId) => {
    try {
        const response = await axios.get(`${API.GET_GENERAL_USER_TABLE.url}/${userId}?action=GET_GENERAL_USER_TABLE`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
    }
};
const getDateForEpaSelectedByTheCoordinator=async(userId)=>{
try{
    const response = await axios.get(`${API.DATE_FOR_EPA_SELECTED_BY_THE_COORDINATORS.url}/${userId}?action=DATE_FOR_EPA_SELECTED_BY_THE_COORDINATORS`);
        if (response.status === 200) {
            return response.data.data;
        }
    }catch(error) {
        console.log(error);
    };


}
export { 
    getCandidates, 
    getGeneralUserTable,
    postAwardindMedalsToTheRooms,
    getCaptainAndHostWithAspirants,
    getDirectorAndCoordinatorWithHostTheIrApplicants,
    getDateForEpaSelectedByTheCoordinator, 
    getListOfHostWithTheirSelectApplicants,
};
