import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import NewCoordinator from "../../../resources/images/new-coordinator.png";
import Book from "../../../resources/images/book.png";
import MoneyHand from "../../../resources/images/money-hand.png";
import GoodHand from '../../../resources/images/good-hand-coordinator.png';
import ClapHand from '../../../resources/images/clap-hand-coordinator.png';
import WarningIcon from '../../../resources/images/warning.png';
import CheckHand from '../../../resources/images/check-hand-coordinator.png';
import GreenCheck from '../../../resources/images/green-check.png';
import Calendar from "../../../resources/images/calendar-coordinator.png";
import BridgeStationCoordinator from "../../../resources/images/epa-station-coordinator.png";
import DirectorCalendar from "../../../resources/images/director-calendar.png";
import CalendarCheckCoordinator from "../../../resources/images/calendar-check-coordinator.png";
import GiftImage from "../../../resources/images/gift-image.png";
import ToolsScreenCoordinator from "../../../resources/images/tools-screen-coordinator.png";
import CoordinatorGift from "../../../resources/images/coordinator-gift.png";
import GraphIcon from "../../../resources/images/graph-icon.png";
import MediaIcon from "../../../resources/images/media-icon.png";
import Card from "../../atoms/card";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import Checkbox from "../../atoms/checkbox";
import CustomDatePicker from '../../molecules/date-picker';
import GiftSwitch from "../../molecules/gift-switch";
import MenuUserInfo from "../../molecules/menu-user-info";
import { formatDate } from "../../../middleware/utils/formatDate";
import PaymentMethodSelector from "../../molecules/payment-method-selector";
import Form from "../../molecules/form"
import TextField from "../../atoms/text-field";
import Select from "../../atoms/select";
import Loader from "../../molecules/loader";
import html2canvas from "html2canvas";
import { getDateForEpaSelectedByTheCoordinator } from "../../../middleware/services/epaCoordinatorServices";
import forge from 'node-forge';
import { PaymentEpaDay } from "../../pages/payment/payment-epa-day";
import { ModalAlertAutocompleEpaDay } from "../../pages/payment/modal-alert-autocomple-epa-day";
import { WaterMark } from "../../molecules/water-mark";



const HostEpaEndDay = ({ finishChallenge, step, setStep, challenge, ...props }) => {
    const { user, eventEpa, transationParam, setTransationParam } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();

    const [dateEpaAvailable, setDateEpaAvailable] = useState([]);
    const [suggestedDate, setSuggestedDate] = useState(null);
    const [hostsSelectedDates, setHostsSelectedDates] = useState([]);
    const [residualDates, setResidualDates] = useState([]);
    const [selectedFoundations, setSelectedFoundations] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedHour, setSelectedHour] = useState(null);
    const [schedule, setSchedule] = useState([]);

    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [timeSelected, setTimeSelected] = useState(false);
    const [dateSelected, setDateSelected] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentBody, setPaymentBody] = useState({});
    const [paymentEncretyBody, setPaymentEncretyBody] = useState({});
    const [billBody, setBillBody] = useState({});

    const [loader, setLoader] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [email, setEmail] = useState('');
    const [terms, setTerms] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [autoComplete, setAutoComplete] = useState(false);
    const [transactionInfo, setTransactionInfo] = useState(null);

    const [newHosts, setNewHosts] = useState([]);

    const [imAvailable, setImAvailable] = useState(false);


    const [epaDateEqual, setEpaDateEqual] = useState(false);
    const [epaDateEqualModal, setEpaDateEqualModal] = useState(true);
    const handleDateChange = (newTime) => {
        setDate(newTime);
        setIsDatePickerOpen(false);
        setDateSelected(true);
    };


    const handleTimeChange = (newTime) => {
        setTime(newTime);
        setIsTimePickerOpen(false);
        setTimeSelected(true);
        setSelectedDate(null);

        setSelectedHour(null);
    };


    const circle = {
        host: '⚪',
        'new host': '⚪',
        coordinator: '🔴',
        'new coordinator': '🔴',
        director: '🟡',
        captain: '🔵',
    };
    const publicDecrypt = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0BY6F1qMy+bbcPzRrxY/
    cqo2sPUO8MkBJTRcf+YSeopD6kpo+Wefsbpnf5pLz4nfmgerPS7ma3dVaqnSqfEd
    bR0a3MlB2AVUy/0KK09btpMBi0fkUVHzg8jNXS8l4M8uRTnF3Nw1Em82MFAj4g9w
    YX/2wNuFzfHX7+I0n4eok2z8YVEnQrCgwDSNN9iZ6odBYaEWJMx68CNY4E79QAms
    IPkzi9L+qHk7qbH97Da5zQeYb19aa6dTKYRaxbzPEu+NsvwHsBzOAoOHELCSwVmu
    /9ftvBbbLKc67PPWruof7ehDGe/IHtHMtkrQdI5RdDQ1qcfcHT1fiGm9YK+PAm3h
    WwIDAQAB
    -----END PUBLIC KEY-----`
    const [listNewRoles, setListNewRoles] = useState([]);
    const [selectedDatesForEpa, setSelectedDatesForEpa] = useState([]);
    const [selectedDatesForEpaFlag, setSelectedDatesForEpaFlag] = useState(false);
    const [alegraProducts, setAlegraProducts] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const saveSelectedDates = () => {
        const dates = [];
        residualDates?.map((item) => {
            item?.residualDates?.map((date) => {
                dates.push(date);
            });
        });

        const isSelected = dates?.some((date) => date?.status);

        const data = {
            data: isSelected ? dates : [{ ...suggestedDate?.[0], status: true }],
        };

        axios.put(`${API.SAVE_SELECTED_DATES_FOR_EPA.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                axios.get(`${API.SUGGESTED_DATE_FOR_EPA.url}/${user?._id}?action=SUGGESTED_DATE_FOR_EPA`).then((response) => {
                    if (response.status === 200) {
                        setSuggestedDate(response.data.data);
                        const newData = {
                            id: 'all',
                            message: 'refresh',
                            action: 'GENERIC_EVENT'
                        };
                        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                            if (response.status === 201) {
                                //setRefresh(!refresh);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                        getDateForEpaSelectedByTheCoordinator(user?.createByHost);
                    }
                }).catch((error) => {
                    console.log(error);
                });

                if (isSelected) {
                    setStep(step + 1);
                } else {
                    setStep(step + 2);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const changeRole = (role) => {
        const data = {
            role: `coordinator`
        };

        axios.put(`${API.CHANGE_OF_ROLE.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                finishChallenge(null);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setPaymentBody({
            ...paymentBody,
            email: e.target.value,
        });
    };

    const saveInfoCard = (e) => {
        e.preventDefault();
        const body = {
            number: e.target.card_number.value,
            action: 'VALIDATE'
        };
        setLoader(true);
        axios.post(`${API.VALIDATE.url}`, body).then((response) => {
            if (response.status === 201) {
                setIsValid(true);
                setPaymentBody({
                    id: user?._id,
                    token: "",
                    acceptance_token: "",
                    number: e.target.card_number.value,
                    cvc: e.target.cvc.value,
                    exp_month: e.target.exp_month.value,
                    exp_year: e.target.exp_year.value,
                    firstName: e.target.first_name.value,
                    lastName: e.target.last_name.value,
                    email: e.target.email.value,
                    documentType: e.target.identification_type.value,
                    identificationNumber: e.target.identification.value,
                    phone: e.target.phone.value,
                    action: 'ADD_CARD',
                });
                setLoader(false);
            }
        }).catch((error) => {
            console.log(error);
            setLoader(false);
        });

    };

    const handlePayment = async (e) => {
        //setTransationParam('')
        e.preventDefault();

        try {

            const getAcceptanceKey = await axios.get(`${API.GET_ACCEPTANCE_KEY.url}?action=GET_ACCEPTANCE_KEY`);
            const priceSplit = ((alegraProducts?.[1]?.price?.[0]?.price * 2) + ((alegraProducts?.[1]?.price?.[0]?.price * 2) * 19 / 100)).toString().split('.');
            const price = parseInt(priceSplit[0] + '00');
            const publicKey = forge.pki.publicKeyFromPem(publicDecrypt);

            const dataCard = {
                number: paymentBody.number,
                cvc: paymentBody.cvc,
                exp_month: paymentBody.exp_month,
                exp_year: paymentBody.exp_year,
            }
            const encrypted = publicKey.encrypt(`${JSON.stringify(dataCard)}`, 'RSA-OAEP');
            const encryptedBase64 = forge.util.encode64(encrypted);
            const body = {
                id: user?._id,
                action: 'ADD_CARD',
                token: "",
                acceptance_token: getAcceptanceKey.data.data.acceptance_token,
                encryptedCardData: encryptedBase64,
                firstName: autoComplete ? paymentBody?.firstName : billBody?.firstName,
                lastName: autoComplete ? paymentBody?.lastName : billBody?.lastName,
                email: autoComplete ? email : billBody?.email === undefined ? email : billBody?.email,
                documentType: autoComplete ? paymentBody?.documentType : billBody?.documentType === undefined ? paymentBody?.documentType : billBody?.documentType,
                documentTypeBill: autoComplete ? paymentBody?.documentType : billBody?.documentType === undefined ? paymentBody?.documentType : billBody?.documentType,

                identificationNumber: autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber === undefined ? paymentBody?.identificationNumber : billBody?.identificationNumber,
                phone: autoComplete ? paymentBody?.phone : billBody?.phone,
                price: price,
                idProduct: alegraProducts?.[1]?.id.toString(),
                firstNameBill: autoComplete ? paymentBody?.firstName : billBody?.firstName,
                lastNameBill: autoComplete ? paymentBody?.lastName : billBody?.lastName,
                identificationNumberBill: autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber === undefined ? paymentBody?.identificationNumber : billBody?.identificationNumber,
                emailBill: billBody?.email === undefined ? email : billBody?.email,
                phoneBill: autoComplete ? paymentBody?.phone : billBody?.phone,
            }
            setLoader(true);
            if (getAcceptanceKey.status === 200 && paymentBody && e.target.checkValidity()) {

                axios.post(`${API.ADD_CARD.url}`, body).then((response) => {

                    if (response.status === 201) {
                        setStep(step + 1);
                        setLoader(false);

                        setTransactionInfo(response.data.data);

                        setTransationParam(response.data.data?.status)

                        const newData = {
                            id: 'all',
                            message: 'refresh',
                            action: 'GENERIC_EVENT'
                        };

                        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                            if (response.status === 201) {
                                //setRefresh(!refresh);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                    setLoader(false);
                });
            } else {
                setLoader(false);
            }
        } catch (error) {
            console.log(error);
            setLoader(false);
        }



    };

    const getSelectedDatesForEpa = async (createByHost) => {
        const data = await getDateForEpaSelectedByTheCoordinator(createByHost);
        const otherCoordinator = data?.find((coordinator) => coordinator._id !== user?._id);
        setEpaDateEqual(otherCoordinator?.selectedDatesForEpa?.[0]?.date === suggestedDate?.[0]?.date);
        setEpaDateEqualModal(otherCoordinator?.selectedDatesForEpa?.[0]?.date === suggestedDate?.[0]?.date)

    }

    useEffect(() => {
        axios.get(`${API.LIST_DATE_EPA_AVAILABLE.url}/${user?.createByHost}?action=LIST_DATE_EPA_AVAILABLE`).then((response) => {
            if (response.status === 200) {
                const data = response.data.data;
                const epaDatesAvailable = data?.epaDatesAvailable?.map((date) => ({
                    ...date,
                    status: false,
                }));
                data.epaDatesAvailable = epaDatesAvailable;
                setDateEpaAvailable(data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.THE_HOSTS_ARE_READY.url}/${user?._id}?action=THE_HOSTS_ARE_READY`).then((response) => {
            if (response.status === 200) {
                setHostsSelectedDates(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.SUGGESTED_DATE_FOR_EPA.url}/${user?._id}?action=SUGGESTED_DATE_EPA`).then((response) => {
            if (response.status === 200) {
                setSuggestedDate(response.data.data);
                if (suggestedDate?.[0]?.date == selectedDatesForEpa) {
                    setSelectedDatesForEpaFlag(false);
                } else {
                    setSelectedDatesForEpaFlag(true)
                }
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.LIST_RESIDUAL_DATES.url}/${user?._id}?action=LIST_RESIDUAL_DATES`).then((response) => {
            if (response.status === 200) {
                setResidualDates(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.SINGLE_COORDINATOR_SCHEDULE.url}/${user?._id}?action=SINGLE_COORDINATOR_SCHEDULE`).then((response) => {
            if (response.status === 200) {
                const schedule = response.data?.data?.schedule?.map((date) => {
                    return {
                        ...date,
                        date: formatDate(date.date),
                    }
                });
                setSchedule(schedule);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES.url}/${user?._id}?action=FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES`).then((response) => {
            if (response.status === 200) {
                setSelectedFoundations(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.GET_PRODUCTS_ALEGRA.url}?action=GET_PRODUCTS_ALEGRA`).then((response) => {
            if (response.status === 200) {
                setAlegraProducts(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.LIST_NEW_ROLES.url}/${user?._id}?action=LIST_NEW_ROLES`).then((response) => {
            if (response.status === 200) {
                setNewHosts(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        getCountries().then((response) => {
            setCountries(response);
        }).catch((error) => {
            console.log(error)
        });

        axios.get(`${API.LIST_NEW_ROLES.url}/${user?._id}?action=LIST_NEW_ROLES`).then((response) => {
            if (response.status === 200) {
                setListNewRoles(response.data.data.filter((newRole) => newRole.role === 'host' || newRole.role === 'new host'));
            }
        }).catch((error) => {
            console.log(error);
        });

    }, [eventEpa]);

    useEffect(() => {
        const allFieldsFilled = Object.values(paymentBody).every(field => field.trim() !== '');
        setIsButtonDisabled(!allFieldsFilled);
    }, [paymentBody]);

    useEffect(() => {
        const allFieldsFilled = Object.values(billBody).every(field => field.trim() !== '' || field?.length > 0);
        //setIsButtonDisabled(!allFieldsFilled);
        setIsButtonDisabled(!autoComplete);

    },[]);


    useEffect(() => {
        if (user) {
            getSelectedDatesForEpa(user?.createByHost);
        }
    }, [user, suggestedDate, eventEpa])

    const formatPrice = (price) => {
        return new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP" }).format(Math.floor(price, 0)).split(',')[0];
    };

    const getCountries = () => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_COUNTRIES.url}?action=GET_COUNTRIES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getStates = (country) => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_STATES.url}/${country}?action=GET_STATES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getCities = (country, state) => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_CITIES.url}/${country}/${state}?action=GET_CITIES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    return (
        <>

            {challengeName === 'Desafío Honor' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={NewCoordinator} alt="new-host" width={209} height={209} />
                    <Paragraph size="s" align="center">
                        ¿¡Listos para hacer historia?! Hoy vas a sellar el compromiso con tu equipo porque aquí, en ACDC, todos compartimos un Legado en común, estamos construyendo el ecosistema de innovación y emprendimiento 4.0 de nuestra Comunidad Épica.
                    </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “Las palabras de un líder y los pasos que da, deben coincidir. Como dice el dicho: “Tu palabra es tu valor y tu valor está en tu palabra.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="epa-end-day-container-button">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Es fundamental que cumplas con tu palabra no solo para lograr tus metas personales y tu PDL sino también para permitir que todo el equipo ACDC pueda realizar con éxito su PDL. De este compromiso de honor depende que:
                    </Paragraph>
                    <Paragraph size="s" align="left">
                        <>
                            Tú {circle[user?.role]} <b>{user?.name} </b>
                            logres generar hasta USD 80.000 al mes.
                            <br />
                            <br />
                        </>
                        {listNewRoles?.filter((newRole) => user?.name !== newRole.name).map((newRole, index) => <>{circle[newRole?.role]} <b>{newRole?.name}</b>
                            <br />
                            logre generar hasta USD 80.000 al mes.
                            {index < listNewRoles?.length && <br />}
                            {index < listNewRoles?.length && <br />}
                        </>)}
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={MoneyHand} alt="Money Hand" className="money-hand" />
                    <Paragraph size="s" align="center">
                        En total, tu equipo ACDC tiene el potencial de generar USD 400 mil al mes. Por lo tanto, el aporte de tu equipo, correspondiente al 10% para el Ecosistema de Innovación y emprendimiento ACDC sería potencialmente de USD 40 mil mensuales, de los cuáles USD 4 mil mensuales irán a las Fundaciones que el equipo elija.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">Fundaciones elegidas por los(as)
                        <br />
                        Nuevos(a) Anfitriones(as)</span>
                    <Paragraph size="s" align="center">
                        Del aporte mensual al Ecosistema, el 10% se destinará a fundaciones. Los(as) Nuevos(as) Anfitriones(as) eligieron el tipo de fundación al que quieren aportar:
                    </Paragraph>
                    {selectedFoundations?.map((foundation) => <div className="foundation-item">
                        <span className="foundation-item__host">⚪  {foundation?.name}</span>
                        {foundation?.fundation?.[0]?.options?.map((option) => <Checkbox label={option.option} checked={true} />)}
                    </div>)}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline>
                        <span className="title">Acuerdo de Honor como nuevo(a) Coordinador(a)</span>
                        <Paragraph size="s" align="center">
                            Reconozco que cada acción que tomo no solo afecta mis metas, sino que también contribuye al logro de las metas de mi equipo ACDC. Así, me comprometo a:
                        </Paragraph>
                        <ul>
                            {challenge?.questions?.[0]?.options?.map((option) => <li>{option.option}.</li>)}
                        </ul>
                    </Card>
                    <Button onClick={() => setStep(step + 1)} role="coordinator">Acepto Acuerdo de Honor</Button>
                </Step>
                <Modal isOpen={step === 7} className="agreements-modal coordinator">
                    <img src={GoodHand} alt="Good Hand" width={142} height={142} />
                    <Paragraph size="s" align="center">
                        ¡Excelente!
                        <br />
                        <br />
                        Ahora que ya hiciste el Acuerdo de Honor, continúa con el video para realizar las actividades de la siguiente pausa.
                        <br />
                        <ul>
                            <li>Ingreso a la Meet.</li>
                            <li>Definir fecha del EPA.</li>
                        </ul>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">
                        ¡Vale!
                    </Button>
                </Modal>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “El honor está conformado por las virtudes de la integridad, la honestidad, el sacrificio, la lealtad y la humildad, pero de un corazón justo y piadoso para aquellos menos poderosos”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={Calendar} alt="Calendar" className="calendar" />
                    <Paragraph size="s" align="center">
                        Agendamiento EPA.
                        <br />
                        <br />
                        Es momento de definir la fecha y hora de tu EPA.
                        <br />
                        <br />
                        A continuación podrás visualizar las fechas que eligieron tus dos Nuevos Anfitriones.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'} className="epa-end-day-container" >
                    {hostsSelectedDates?.map((host) => <>
                        <Paragraph size="s" align="center">
                            {host.name}
                            <br />
                            ⚪ Nuevo(a) Anfitrión(a)
                            <br />
                            Eligió como posibles fechas para el EPAs:
                        </Paragraph>
                        {host.selectedDatesForEpa?.map((date) => <>
                            <div className="checkbox-table no-margin">
                                <div className="checkbox-table__header">
                                </div>
                                <div className="checkbox-table__item">
                                    <span className="checkbox-table__item__start-time">
                                        <span className="icon-calendar"></span>
                                        {date.date}
                                    </span>
                                    <span className="checkbox-table__item__end-time">{date.hour}</span>
                                </div>
                            </div>
                        </>)}
                    </>)}
                    <Button onClick={() => {
                        setStep(step + 1);
                    }} disabled={hostsSelectedDates?.length < 1}>Continuar</Button>
                </Step>


                <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Modal isOpen={epaDateEqualModal} className="agreements-modal coordinator">

                        <img src={WarningIcon} alt="Warning " width={142} height={142} />
                        <Paragraph size="s" align="center">
                            ¡Ups!
                            <br />
                            <br />
                            Esta fecha para el EPA, ya fue asignada para el otro equipo. Por favor  presiona el Botón “Elegir nueva fecha” y  selecciona una opción diferente.
                            <br />
                        </Paragraph>
                        <Button onClick={() => setEpaDateEqualModal(!epaDateEqualModal)} size="small">
                            ¡Vale!
                        </Button>
                    </Modal>
                    {
                        !epaDateEqualModal && (
                            <>
                                <Paragraph size="s" align="center">
                                    Por lo tanto,
                                    <br />
                                    Nuevo(a) Coordinador(a) la mejor fecha
                                    <br />
                                    para el EPA es:
                                </Paragraph>
                                <div className="checkbox-table">
                                    <div className="checkbox-table__header">
                                    </div>
                                    <div className="checkbox-table__item">
                                        <span className={`checkbox-table__item__start-time border`}>
                                            <span className="icon-calendar"></span>
                                            {suggestedDate?.[0]?.date}
                                        </span>
                                        <span className={`checkbox-table__item__end-time border`}>{suggestedDate?.[0]?.hour}</span>
                                    </div>
                                </div>
                                <img src={BridgeStationCoordinator} alt="Bridge Station Coordinator" className="bridge-station-coordinator" />
                                <Button onClick={() => setStep(step + 1)} style="ghost" size="small">Elegir nueva fecha</Button>

                                <Paragraph size="s" align="center">
                                    Presiona el botón "Elegir nueva fecha" en caso de que no funcione para ti la fecha definida. La nueva fecha deberá ser acordada con tu Director(a).
                                </Paragraph>
                                <Button onClick={() => {
                                    saveSelectedDates();
                                }} role="coordinator"
                                    disabled={epaDateEqual}>Aceptar Fecha</Button>
                            </>
                        )
                    }

                </Step>
                <Step order={12} visibility={step === 12 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Elige una de las posibles fechas disponibles:

                    </Paragraph>
                    {residualDates?.map((item) => <div className="checkbox-table no-margin">
                        <div className="checkbox-table__header">
                        </div>
                        {item?.residualDates?.map((date) => {
                            return <div className="checkbox-table__item">
                                <Checkbox
                                    checked={selectedDate?.includes(date.date)}
                                    onChange={() => {
                                        setTimeSelected(false);
                                        setDateSelected(false);
                                        setSelectedDate(date.date);
                                        setSelectedHour(date.hour);
                                        date.status = !date.status;
                                    }}
                                />
                                <span className="checkbox-table__item__start-time">
                                    {date.date}
                                </span>
                                <span className="checkbox-table__item__end-time">{date.hour}</span>
                            </div>
                        })}
                        {item?.residualDates && (item?._id === user?._id ? <span className="checkbox-table__item extra"></span> : <span className="checkbox-table__item extra">No elegida por {item?.name}</span>)}
                    </div>)}
                    <div className="checkbox-table no-margin">
                        <div className="checkbox-table__header">
                        </div>
                        {/* <div className="checkbox-table__item">
                            <Checkbox
                                checked={timeSelected && dateSelected}
                                onChange={() => {
                                }}
                            />
                            <span className="checkbox-table__item__start-time" onClick={() => setIsDatePickerOpen(true)}>
                                {dateSelected ? date?.toLocaleDateString('es') : 'Crear fecha s'}
                            </span>
                            <span className="checkbox-table__item__end-time" onClick={() => setIsTimePickerOpen(true)}>{timeSelected ? time.toLocaleTimeString('es') : '0:00_'}</span>
                        </div> */}
                        <span className="checkbox-table__item extra">Elige una fecha del calendario.</span>
                    </div>
                    <CustomDatePicker
                        isOpen={isDatePickerOpen}
                        onSelect={handleDateChange}
                        time={date}
                        min={new Date(date.getFullYear(), date.getMonth(), date.getDate() + 8)}
                        max={new Date(date.getFullYear(), date.getMonth(), date.getDate() + 15)}
                        role={user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role}
                        onCancel={() => setIsDatePickerOpen(false)}
                    />
                    <CustomDatePicker
                        isOpen={isTimePickerOpen}
                        onSelect={handleTimeChange}
                        time={time}
                        role={user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role}
                        onCancel={() => setIsTimePickerOpen(false)}
                        isTimePicker
                    />
                    <Button onClick={saveSelectedDates}>Aceptar Fecha</Button>
                </Step>
                <Step order={13} visibility={step === 13 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        <b>Confirmación de fecha para el EPA</b>
                        <br />
                        Tu director está revisando la fecha definida para cada EPA...

                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        <div className="checkbox-table__item">
                            <span className={`checkbox-table__item__start-time border`}>
                                <span className="icon-calendar"></span>
                                {selectedDate != null ? selectedDate : suggestedDate?.[0]?.date}
                            </span>
                            <span className={`checkbox-table__item__end-time border`}>{selectedHour != null ? selectedHour : suggestedDate?.[0]?.hour}</span>
                        </div>
                    </div>
                    <img src={DirectorCalendar} alt="Director Calendar" className="director-calendar-image" />
                    <Paragraph size="s" align="center" >
                        Una vez tu director confirme la fecha se habilitará el Botón Continuar EPA
                    </Paragraph>
                    <Checkbox
                        label="Confirmo que estoy disponible y que participaré del EPA en la fecha y hora definidas."
                        checked={imAvailable}
                        onChange={() => setImAvailable(!imAvailable)}
                    />
                    <Button onClick={() => setStep(step + 1)} role="coordinator" disabled={!imAvailable}>Continuar EPA</Button>
                </Step>
                <Step order={14} visibility={step === 14 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        ¡Nuevo(a) Coordinador(a)!
                        <br />
                        <br />
                        Es momento de definir los días del cronograma. El día de Inducción será mañana y el Día de Alineamiento será un día antes del EPA.
                        <br />
                        <br />
                        El nuevo Director podrá modificar el Día de Reglas que moverá las demás fechas hasta el Día de Filtro.
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        {schedule?.map((date, index) => <div className="checkbox-table__item">
                            <span className={`checkbox-table__item__start-time days`}>
                                {date.day}
                            </span>
                            <span className={`checkbox-table__item__end-time ${index > 0 ? 'border' : ''} days`}>{date.date}</span>
                        </div>)}
                    </div>
                    <Paragraph size="xs" align="center" >
                        Podrás avanzar cuando el(la) Nuevo(a) Director(a) confirme las fechas de cronograma.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="coordinator" >Continuar</Button>
                </Step>
                <Step order={15} visibility={step === 15 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        ¡Genial!
                        <br />
                        <br />
                        Has completado el <b>Desafío Honor</b> del Final del EPA
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="background-p">
                        Ya puedes iniciar el <b>Desafío Humildad</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button onClick={() => finishChallenge(null)} size="small" style="ghost">Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Humildad' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={CalendarCheckCoordinator} alt="Calendar Check Host" className="calendar-check-host" />
                    <Paragraph size="s" align="center">
                        Ahora que ya definiste la fecha y hora del EPA, continúa en la Meet con tus dos nuevos Anfitriones para realizar las actividades de las siguientes pausas del video.
                        <br />
                        <br />
                        En este momento deberás hacer la invitación al grupo de whatsapp a tus dos Nuevos(as) Anfitriones(as).
                    </Paragraph>
                    <Button onClick={() => { }} size="small" style="ghost">Ver video</Button>
                    <Paragraph size="s" align="center" className="background-p">
                        Podrás continuar cuando tu Nuevo(a) Director(a) confirme que se han agregado los dos Nuevos Anfitriones al grupo de Whatsapp.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="coordinator">Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “No existen secretos para alcanzar el éxito. Este es el resultado de la preparación, el trabajo arduo y de aprender de los fracasos”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">¡Compra Obsequios!</span>
                    <img src={CoordinatorGift} alt="Coordinator Gift" className="coordinator-gift" />
                    <Paragraph size="s" align="center">
                        Es momento que tus dos Nuevos Anfitriones accedan al contenido y a la plataforma de ACDC.
                    </Paragraph>
                    <Paragraph size="s" align="center" className="background-p">
                        Tú les obsequiarás el acceso a la plataforma y ellos pagarán el acceso al contenido audiovisual.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title"><span className="icon-shop-bag"></span> Módulo de compra ACDC</span>
                    <div className="buy-item">
                        <img src={GraphIcon} className="buy-item__logo" />
                        <div className="buy-item__info">
                            <span className="buy-item__info__name"><span className="icon-gift"></span> {alegraProducts?.[1]?.name}</span>
                            <span className="buy-item__info__description">(Obsequio)</span>
                            <span className="buy-item__info__price">{formatPrice(alegraProducts?.[1]?.price?.[0]?.price)} COP</span>
                        </div>
                    </div>
                    <div className="buy-item disabled">
                        <img src={MediaIcon} className="buy-item__logo" />
                        <div className="buy-item__info">
                            <span className="buy-item__info__name">Contenido Audiovisual ACDC</span>
                            <span className="buy-item__info__description">(Obsequio)</span>
                            <span className="buy-item__info__price">$ 42.016 COP</span>
                        </div>
                    </div>
                    <table className="buy-table">
                        <tr>
                            <th></th>
                            <th align="left">($COP)</th>
                            <th align="left">Cant.</th>
                        </tr>
                        <tr>
                            <th align="right">Niveles Plataforma:</th>
                            <td>{formatPrice(alegraProducts?.[1]?.price?.[0]?.price)}</td>
                            <td align="center">2</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th align="right">Subtotal:</th>
                            <td>{formatPrice(alegraProducts?.[1]?.price?.[0]?.price * 2)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th align="right">IVA(19%):</th>
                            <td>{formatPrice(((alegraProducts?.[1]?.price?.[0]?.price * 2) * 19 / 100))}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th align="right">Total a pagar:</th>
                            <td colSpan={2} className="border">{formatPrice((alegraProducts?.[1]?.price?.[0]?.price * 2) + ((alegraProducts?.[1]?.price?.[0]?.price * 2) * 19 / 100))}<span>COP</span></td>
                        </tr>
                    </table>
                    <Button onClick={() => setStep(step + 1)}>Iniciar pago</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">Elige el método de pago</span>
                    <PaymentMethodSelector callback={(e) => setPaymentMethod(e)} />
                    <WaterMark />
                    <Paragraph size="xs" align="center">
                        Selecciona un método para poder continuar
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={!paymentMethod}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">Por favor ingresa los datos de tu tarjeta</span>
                    <Form className="card-form" onSubmit={saveInfoCard}>
                        <TextField
                            name="first_name"
                            placeholder="Primer nombre"
                            onChange={(e) => {
                                setPaymentBody({
                                    ...paymentBody,
                                    firstName: e.target.value,
                                });
                            }}
                            required />
                        <TextField
                            name="last_name"
                            placeholder="Primer apellido"
                            onChange={(e) => {
                                setPaymentBody({
                                    ...paymentBody,
                                    lastName: e.target.value,
                                });
                            }}
                            required />
                        <div className="card-form__item">
                            <span className="card-form__item__number">Número de tarjeta</span>
                            <TextField
                                name="card_number"
                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        number: e.target.value,
                                    });
                                }}
                                maxLength={16}
                                required />
                        </div>
                        <div className="card-form__item">
                            <span className="card-form__item__exp_month">Mes de Expiración</span>
                            <TextField
                                name="exp_month"
                                placeholder="MM"
                                pattern="0[1-9]|1[0-2]"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        exp_month: e.target.value,
                                    });
                                }}
                                maxLength={2}
                                required />
                            <span className="card-form__item__exp_year">Año de Expiración</span>
                            <TextField
                                name="exp_year"
                                placeholder="AA"
                                pattern="[0-9]{2}"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        exp_year: e.target.value,
                                    });
                                }}
                                maxLength={2}
                                required />
                        </div>
                        <div className="card-form__item cvc">
                            <span className="card-form__item__cvc">CVC</span>
                            <TextField
                                name="cvc"
                                placeholder="XXX"
                                pattern="[0-9]{3}"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        cvc: e.target.value,
                                    });
                                }}
                                maxLength={3}
                                required />
                        </div>
                        <div className="card-form__item identification">
                            <span className="card-form__item__identification">Doc. de identidad</span>
                            <Select name="identification_type" onChange={(e) => {
                                setPaymentBody({
                                    ...paymentBody,
                                    documentType: e.target.value,
                                });
                            }}>
                                <option value="CC">CC</option>
                                <option value="NIT">NIT</option>
                            </Select>
                            <TextField
                                name="identification"
                                placeholder="0000000000"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        identificationNumber: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <TextField
                            name="email"
                            placeholder="Correo electrónico"
                            leftIcon={{ icon: 'icon-mail' }}
                            pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                            value={email}
                            onChange={handleChangeEmail}
                            required />
                        <div className="card-form__item">
                            <span className="card-form__item__phone">Teléfono</span>
                            <TextField
                                name="phone"
                                placeholder="0000000000"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        phone: e.target.value,
                                    });
                                }}
                                required />
                        </div>
                        <Paragraph size="xs" align="center">
                            Tus datos solo se utilizarán para procesar tu pedido y mejorar tu experiencia en esta plataforma.
                        </Paragraph>
                        <Checkbox
                            label="He leído y estoy de acuerdo con los términos y condiciones de la web"
                            checked={terms}
                            onChange={() => setTerms(!terms)}
                        />
                        {/* <Button disabled={!terms || isButtonDisabled}>Continuar</Button> */}
                         <Button  size="small" disabled={!terms}>Continuar</Button> 
                    </Form>
                </Step>
               
                <ModalAlertAutocompleEpaDay isValid={isValid} setIsValid={setIsValid} setStep={setStep} step={step} className="host-alignment-day-container epa-confirmation-modal fit-content"/>
                <Loader isShow={loader} />
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    {/* <span className="title">Ahora ingresa los detalles de facturación y completa la compra</span>
                    <Checkbox
                        label="Autocompletar datos"
                        checked={autoComplete}
                        onChange={() => setAutoComplete(!autoComplete)}
                    />
                    <Form className="card-form" onSubmit={handlePayment}>
                        <div className="card-form__item identification">
                            <span className="card-form__item__identification">Doc. de identidad</span>
                            <Select name="identification_type" onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    documentType: e.target.value===""?"CC":e.target.value,
                                });
                            }}>
                                <option value="CC">CC</option>
                                <option value="NIT">NIT</option>
                            </Select>
                            <TextField
                                name="identification"
                                placeholder="0000000000"
                                onChange={(e) => {
                                    setBillBody({
                                        ...billBody,
                                        identificationNumber: e.target.value,
                                    });
                                }}
                                value={autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber}
                                required
                            />
                        </div>
                        <TextField
                            name="name"
                            placeholder="Nombres"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    firstName: e.target.value,
                                });
                            }}
                            value={autoComplete ? paymentBody?.firstName : billBody?.firstName}
                            required />
                        <TextField
                            name="last_name"
                            placeholder="Apellidos"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    lastName: e.target.value,
                                });
                            }}
                            value={autoComplete ? paymentBody?.lastName : billBody?.lastName}
                            required />
                        <TextField
                            name="company"
                            placeholder="Nombre de la empresa (opcional)"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    company: e.target.value,
                                });
                            }}
                        />
                        <Select name="country" onChange={(e) => {
                            setBillBody({
                                ...billBody,
                                country: e.target.value,
                            });
                            getStates(e.target.value).then((response) => {
                                setStates(response);
                            }).catch((error) => {
                                console.log(error);
                            });
                        }} required>
                            <option value="">País / Región</option>
                            {countries?.map((country) => <option value={country.isoCode}>{country.name}</option>)}
                        </Select>
                        <Select name="state" onChange={(e) => {
                            setBillBody({
                                ...billBody,
                                state: e.target.value,
                            });
                            getCities(billBody?.country, e.target.value).then((response) => {
                                setCities(response);
                            }).catch((error) => {
                                console.log(error);
                            });
                        }} required>
                            <option value="">Departamento</option>
                            {states?.map((state) => <option value={state.isoCode}>{state.name}</option>)}
                        </Select>
                        <Select name="state" onChange={(e) => { }} required>
                            <option value="">Ciudad</option>
                            {cities?.map((city) => <option value={city.isoCode}>{city.name}</option>)}
                        </Select>
                        <TextField
                            name="address"
                            placeholder="Dirección de residencia"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    address: e.target.value,
                                });
                            }}
                            required />
                        <TextField
                            name="email"
                            placeholder="Correo electrónico"
                            leftIcon={{ icon: 'icon-mail' }}
                            pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                            value={autoComplete ? paymentBody?.email : billBody?.email}
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    email: e.target.value,
                                });
                            }}
                            required />
                        <div className="card-form__item">
                            <span className="card-form__item__phone">Teléfono</span>
                            <TextField
                                name="phone"
                                placeholder="0000000000"
                                pattern="[0-9]{10}"
                                onChange={(e) => {
                                    setBillBody({
                                        ...billBody,
                                        phone: e.target.value,
                                    });
                                }}
                                value={autoComplete ? paymentBody?.phone : billBody?.phone}
                                required />
                        </div>
                        <Button  disabled={isButtonDisabled}>Continuar</Button>
                    </Form> */}
                    <PaymentEpaDay email={email} setLoader={setLoader} setTransactionInfo={setTransactionInfo} alegraProducts={alegraProducts} setAlegraProducts={setAlegraProducts} paymentBody={paymentBody} setPaymentBody={setPaymentBody} step={step} setStep={setStep} />
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-end-day-container host">



                    {(transactionInfo?.status === 'APPROVED') ? <>
                        <img src={GreenCheck} alt="Green Check" className="green-check" />
                        <span className="title">Transacción aprobada</span>
                    </> : <>
                        <img src={WarningIcon} alt="Warning" className="warning" />
                        <span className="title">Transacción rechazada</span>
                    </>}
                    <Card type="tertiary" >
                        <span className="card-title">Información de la transacción</span>
                        <div className="card-item">
                            <span className="card-item__title">Transacción #:</span>
                            <span className="card-item__value">{transactionInfo?.id}</span>
                        </div>
                        <div className="card-item">
                            <span className="card-item__title">Referencia:</span>
                            <span className="card-item__value">{transactionInfo?.reference}</span>
                        </div>
                        <div className="card-item last">
                            <span className="card-item__title">Email:</span>
                            <span className="card-item__value">{email}</span>
                        </div>
                    </Card>
                    <Card type="tertiary" >
                        <span className="card-title">Información del pagador</span>
                        <div className="card-item">
                            <span className="card-item__title">Nombre:</span>
                            <span className="card-item__value">{paymentBody?.firstName} {paymentBody?.lastName}</span>
                        </div>
                        <div className="card-item last">
                            <span className="card-item__title">Teléfono:</span>
                            <span className="card-item__value">{paymentBody?.phone}</span>
                        </div>
                    </Card>
                    <Button onClick={() => {
                        html2canvas(document.getElementById('step-8')).then(canvas => {
                            const link = document.createElement('a');
                            link.download = `${transactionInfo?.id}.png`;
                            link.href = canvas.toDataURL();
                            link.click();
                        });
                    }} size="small" style="ghost">Imprimir</Button>

                    {(transactionInfo?.status === 'APPROVED') ? <>
                        <Button onClick={() => setStep(step + 1)}>Finalizar transacción</Button>
                    </> : <>
                        <Button onClick={() => setStep(step - 1)}>Volver al método de pago</Button>
                    </>}




                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="epa-end-day-container host-alignment-day-container">
                    <Paragraph size="s" align="center" className="gift-text">
                        <span className="icon-gift primary"></span>
                        <br />
                        Visualizarás la tarjeta encendida del Nuevo(a) Anfitrión(a) que ya haya efectuado el pago del Contenido Audivisual ACDC
                    </Paragraph>
                    <div className="gift-switch-container">
                        {listNewRoles?.map((newRole) => <GiftSwitch role={'host'} nameRole={'Nuevo(a) Anfitrión(a)'} name={newRole?.name} range={7} onClick={(e) => { }} items={[
                            {
                                image: GraphIcon,
                                title: 'Niveles de plataforma ACDC',
                                wait: newRole?.paymentLevelsAcdcPlatform !== 'APPROVED' && newRole?.paymentLevelsAcdcPlatform !== 'PENDING'
                            },
                            {
                                image: MediaIcon,
                                title: 'Contenido Audiovisual ACDC',
                                wait: newRole?.paymentForAudiovisualContentAcdc !== 'APPROVED' && newRole?.paymentForAudiovisualContentAcdc !== 'PENDING'
                            }
                        ]} />)}
                    </div>
                    <Paragraph size="xs" align="center">
                        Podrás continuar cuando ambas tarjetas de los Nuevos(as) Anfitriones(as) estén encendidas.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="coordinator">Continuar EPA</Button>
                </Step>
                <Modal isOpen={step === 10} className="host-alignment-day-container epa-confirmation-modal fit-content">
                    <img src={GiftImage} alt="Gift Image" className="gift-image" />
                    <span className="title">¡Súper!</span>
                    <Paragraph size="s" align="center">
                        Tus Nuevos Anfitriones ya recibieron el obsequio de tu parte, ahora ellos pueden acceder al contenido que requieren y a la plataforma para aplicar la estrategia.
                        <br />
                        <br />
                        Mañana iniciarás con el día de Inducción junto con tu equipo.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Vale!</Button>
                </Modal>
                <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        ¡Genial!
                        <br />
                        <br />
                        Has completado el <b>Desafío Humildad</b> del Final del EPA
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="background-p">
                        Ya puedes iniciar el <b>Desafío Hambre</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Hambre' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “Los Líderes comprenden que los resultados derivan del Esfuerzo Colectivo.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="epa-end-day-container-button">Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        ¡Momento de agradecer!
                    </Paragraph>
                    <img src={ToolsScreenCoordinator} alt="Tools Screen Coordinator" className="tools-screen-coordinator" />
                    <Paragraph size="s" align="center">
                        Tus nuevos Anfitriones van a realizar el entrenamiento contigo de la clave A (Agradecimiento) del ABC de la Convocatoria consultando las claves al Nuevo TWOBOT  (Nuevo(a) Capitán(a))..
                        <br />
                        <br />
                        En la siguiente pantalla, marca la casilla la casilla del(la) Anfitrión(a) con quien hayas realizado ya el Entrenamiento.
                    </Paragraph>
                    <Button onClick={() => { }} size="small" style="ghost">Ver video</Button>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Las casillas de los validadores se habilitan una vez tu Nuevo Coordinador confirma que realizaste el entrenamiento.
                    </Paragraph>
                    {newHosts?.filter((personalReference) => personalReference.role === 'host' || personalReference.role === 'new host')?.map((personalReference, index) => <div className="training-completed">
                        <MenuUserInfo orientation="row" name={personalReference?.name} personalReference={user?.role?.split(' ')?.[1] || user?.role} nameRole={'Nuevo(a) Anfitrión(a)'} range={7} />
                        <div className="training-completed__table">
                            <span className="training-completed__table__column text">Entrenamiento completado</span>
                            <div className="training-completed__table__column">
                                <Checkbox
                                    checked={personalReference?.gratitudeDone}
                                    disabled={personalReference?.gratitudeDone}
                                    onChange={() => {
                                        axios.post(`${API.COMPLETED_TRAINING_OF_KEY_A.url}/${personalReference._id}`).then((response) => {
                                            if (response.status === 201) {
                                                const newData = {
                                                    id: 'all',
                                                    message: 'refresh',
                                                    action: 'GENERIC_EVENT'
                                                };
                                                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                                                    if (response.status === 201) {
                                                        //setRefresh(!refresh);
                                                    }
                                                }).catch((error) => {
                                                    console.log(error);
                                                });
                                            }
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="training-completed__table">
                            <span className="training-completed__table__column text">Agradecimientos Realizados:</span>
                            <div className="training-completed__table__column">
                                {personalReference.gratitudeDoneCount || 0}
                            </div>
                        </div>
                    </div>)}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        <b>¡Felicitaciones!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        ¡Has completado los desafíos del final del EPA! Mañana es el día de Inducción del siguiente nivel de influencia, Desempeño!
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Button onClick={changeRole}>Finalizar</Button>
                </Step>
            </>}
        </>
    );
};

export default HostEpaEndDay;