import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import MedalSwitch from "../medal-switch";
import { ROLES } from "../../../middleware/utils/constants";
import { useParams } from "react-router-dom";
import { getCaptainAndHostWithAspirants } from "../../../middleware/services/epaCoordinatorServices";
import { assignMedal } from "../../../middleware/services/common";


const DirectorCaptainAndHostWithAspirants = ({ idCoordinator,medals, setMedals, className, role }) => {
    const { user, eventEpa, setEventEpa } = useContext(UserContext);

    const { challengeName, idChallenge } = useParams();
    const [captainAndHosts, setCaptainAndHosts] = useState([]);

    useEffect(() => {
  
        fetchData();

    }, [idCoordinator,eventEpa]);
    const fetchData = async () => {
     
       
        const list = await getCaptainAndHostWithAspirants(idCoordinator);

        setCaptainAndHosts(list);
        return

    };

    return (
        <div className={`${className}`}>

            {
                role !== '' ? (<>

                    {captainAndHosts?.filter((candidate) => candidate.role === role)?.map((candidate) => <MedalSwitch
                        roleName={ROLES[candidate.role]?.roleName}
                        name={candidate.name}
                        circle={ROLES[candidate.role]?.circle}
                        disabled={role==='coordinator' || role==='director' ||role==='host' ?true:candidate.disabled}
                        value={role==='coordinator' || role==='director' ||role==='host'?candidate.challenges.some(challenge=>challenge?.justInTime!==undefined || challenge?.justInTime>0)?"No":"Si":role==='candidate'?candidate.challenges.some(challenge=>challenge?.recognition!==undefined)?"Si":"No": "No"}
                        onClick={(value) => {
                            setMedals([...medals, { value, name: candidate.name }]);
                            assignMedal('timeManagement', 'si', candidate._id, idChallenge);
                        }} />)}

                </>) : (<>
                    {captainAndHosts?.map((candidate) => <MedalSwitch
                        roleName={ROLES[candidate.role]?.roleName}
                        name={candidate.role}
                        circle={ROLES[candidate.role]?.circle}
                        disabled={candidate.role==='coordinator'?false:candidate.role==='director'?true:candidate.role==='host'?true: candidate.disabled}
                      
                        onClick={(value='Si') => {
                            setMedals([...medals, { value, name: candidate.name }]);
                            assignMedal('timeManagement',value, candidate._id, idChallenge);
                        }} />)}

                </>)

            }
            <br />
        </div>
    );
};

export default DirectorCaptainAndHostWithAspirants;