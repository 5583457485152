import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { componentMap, ROLES } from "../../../middleware/utils/constants";
import * as SERVICES from "../../../middleware/services/useQueryListOfNewHosts";





const AuxComponent = ({ brick, renderMap, idUser ,idChallenge}) => {
  
  const [bricks, setBricks] = useState([]);

  const fetchData = async () => {
    const data = await SERVICES[brick.bricks]?.(idUser,idChallenge);
    setBricks(data);
  };

  useEffect(() => {
    fetchData();
  }, [brick]);

  const Component = componentMap[brick.type];
  return <Component {...brick.props}>{bricks ? bricks?.map((brick) => renderMap(brick)) : brick.children}</Component>;
};


export const DynamicChallenge = ({
  challenge,
  step,
  setStep,
  finishChallenge,
}) => {
  const { user } = useContext(UserContext);
  const [updateBricks, setUpdateBricks] = useState('');
  const [selectedId, setSelectedId] = useState('');
  
  const currentChallenge = ROLES[user?.role].challenges[challenge?.day]?.[challenge?.typeChalleges - 1];
  const [bricks, setBricks] = useState(currentChallenge?.bricks?.(user?._id));
  
  const dynamicData = useRef(null);
const findEvent = (bricks, id, split) => {

    if (typeof bricks === 'string') {
      return bricks;
    }

    return bricks?.map((brickFind) => {
      if (brickFind.id === id || brickFind.id === split[0] || brickFind.id === selectedId) {
        const isOpen = brickFind.type === 'listModal' || brickFind.type === 'modal' ? true : undefined;
        const value = brickFind.id === selectedId && brickFind.type === 'textField' ? split[1] : undefined;
        console.log('split1',split[1], brickFind.counter);
        const counter = brickFind.id === 'continuePutAddToWhatSapp' && brickFind.type === 'button' && split[1] === 'true' ? brickFind.counter + 1 : brickFind.counter - 1;
        console.log('counter', counter);
        const disabled = brickFind.id === 'continuePutAddToWhatSapp' && brickFind.type === 'button' && counter === 2 ? false : true;
        console.log('disabled', disabled);
        return {
          ...brickFind,
          props: {
            ...brickFind?.props,
            isOpen,
            onClose: () => setUpdateBricks(''),
            value,
            disabled,
          },
          counter,
        }
      }
      if (brickFind.bricks) {
        brickFind.bricks = findEvent(brickFind.bricks, id, split);
      }

      return brickFind;
    });
  };

  useEffect(() => {
    const split = updateBricks.split('-');
    if (updateBricks !== '') {
      const newBricks = findEvent(bricks, updateBricks, split);

      setBricks(newBricks);
    } else {
      setBricks(currentChallenge?.bricks?.(user?._id));
    }
  }, [updateBricks]);


  useEffect(() =>{
    SERVICES[dynamicData.current]?.(user?._id,challenge?._id).then((data) => {
      console.log('data', data.length);
      setBricks([...bricks, ...data]);
    });
  }, [dynamicData.current])


  const renderMap = (brick, index) => {
    const Component = componentMap[brick.type];
    if(typeof brick === 'string') {
      dynamicData.current = brick;
    }
    if (typeof brick?.bricks === 'string') {
      return <AuxComponent brick={brick} renderMap={renderMap} idUser={user?._id} idChallenge={challenge?._id} />;
    }
    if (brick.props?.order) {
      const newProps = {
        ...brick.props,
        visibility: brick.props.order === step ? 'visible' : 'hidden',
      };
      brick.props = newProps;
    }
    if (brick?.action === 'next') {
      const newProps = {
        ...brick.props,
        onClick: () => setStep(step + 1),
      };
      brick.props = newProps;
    }
    if (brick?.action === 'finish') {
      const newProps = {
        ...brick.props,
        onClick: () => finishChallenge(),
      };
      brick.props = newProps;
    }
    if (brick?.action === 'handleSubmitPersonalReferences') {
      
      const newProps = {
        ...brick.props,
        onClick: () => setStep(step + 1),
      };
      brick.props = newProps;
    }
    if (typeof brick?.action === 'object' && brick?.action?.action === 'openModalSelect') {
      let extraProps = {};
      if (brick?.type !== 'button') {
        extraProps = {
          rightIcon: {
            ...brick.props.rightIcon,
            onClick: () => {
              setSelectedId(brick.id);
              setUpdateBricks(brick.action.id);
            },
          }
        };
      } else {
        extraProps = {
          onClick: () => {
            setSelectedId(brick.id);
            setUpdateBricks(brick.action.id);
          },
        };
      }
      const newProps = {
        ...brick.props,
        ...extraProps,
      };
      brick.props = newProps;
    }
    if (brick?.props?.items) {
      const newProps = {
        ...brick.props,
        items: brick?.props?.items?.map((item) => ({
          ...item,
          onClick: () => {
            setUpdateBricks(`${item.action.id}-${item.name}`);
            setTimeout(() => {
              setUpdateBricks('');
            }, 100);
          },
        })),
      };
      brick.props = newProps;
    }
    if (brick?.action === 'putAddToWhatSapp') {
      const newProps = {
        ...brick.props,
        onClick: (e) => {
          setUpdateBricks(`continuePutAddToWhatSapp-${e.target.checked}-${brick.props.id}`);
          // SERVICES[brick.action]?.(user?._id);
        },
      };
      brick.props = newProps;
    }
    return typeof brick !== 'string' && <Component {...brick.props}>{brick.bricks ? brick.bricks.map((brick) => renderMap(brick)) : brick.children}</Component>;

  };

  return <>{bricks?.map((brick, index) => renderMap(brick, index))}</>;
};