import React, { useContext, useEffect } from 'react'

import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'

import './index.scss';
import { QueriesListNewRoles, TestgetListNewRoles } from '../../../middleware/services/useQueryListNewRolesServices';
import { UserContext } from '../../../middleware/providers/user-context';
import axios from 'axios';
import { API, ROLES } from '../../../middleware/utils/constants';
const circle = {
  host: '⚪',
  'new host': '⚪',
  coordinator: '🔴',
  'new coordinator': '🔴',
  director: '🟡',
  captain: '🔵',
};
export const ChallengeHonorThird = ({ setStep, step }) => {
  const { user } = useContext(UserContext)
  const { data } = QueriesListNewRoles(user?._id);
  return (
    <>
      <Paragraph size="s" align="justify">
        Es fundamental que cumplas con tu palabra no solo para lograr tus metas personales y tu PDL sino también para permitir que todo el equipo ACDC pueda realizar con éxito su PDL. De este compromiso de honor depende que:
      </Paragraph>
      <Paragraph size="s" align="left">
       
        {  data && data.map((item, index) => {
        // Si el elemento es un array, lo iteramos y mostramos sus datos
        if (Array.isArray(item) ) {
          return (
          
           <>
            
                {item?.map((subItem) => subItem.role !=="candidate" && (
                   <>
                   Tu {ROLES[subItem.role]?.circle}<b>{subItem.name}. </b><br />
                   logres generar hasta USD 80.000 al mes.
                   <br />
                   <br />
                 </>
                  
                ))}
            
            </>
          );
        } else {
          // Si el elemento no es un array, mostramos sus datos directamente
          return (
          
                    <>
                   Tu {ROLES[item.role]?.circle}<b>{item.name} </b><br />
                   logres generar hasta USD 80.000 al mes.
                   <br />
                   <br />
                 </>
      
          );
        }
      })}

      </Paragraph>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Button onClick={() => setStep(step + 1)}>Continuar</Button>



    </>
  )
}
