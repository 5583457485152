import React, { useContext, useEffect, useState } from 'react'

import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'

import './index.scss';
import { API } from '../../../middleware/utils/constants';
import { UserContext } from '../../../middleware/providers/user-context';
import axios from 'axios';


export const ChallengeHonorSix = ({ setOpenModal, setStep, step }) => {

  const user = useContext(UserContext)
  const [selectedFoundations, setSelectedFoundations] = useState([]);
  useEffect(() => {

    axios.get(`${API.FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES.url}/${user?._id}?action=FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES`).then((response) => {
      if (response.status === 200) {
        setSelectedFoundations(response.data.data);
      }
    }).catch((error) => {
      console.log(error);
    });
  }, [])
  return (
    <>
      <div className="card-host card-host-border card-host-border-style" type="secondary" outline>
        <span className="title-honor">Acuerdo de Honor<br /> </span>
        <span className="title-honor"> como nuevo(a) Coordinador(a)</span>
        <br />
        <br />
        <Paragraph size="s" align="center">
          Reconozco que cada acción que tomo no solo afecta mis metas, sino que también contribuye al logro de las metas de mi equipo ACDC. Así, me comprometo a:
        </Paragraph>
        <br />
        <ul>
          <li>Cumplir con las misiones diarias del cronograma.</li>
          <li>Mantener comunicación transparente y colaborativa.</li>
          <li>Partocopar en las fechas y horarios establecidos de los EPAs.</li>
          <li>Mantener y mejorar mi Desempeño.</li>
          <li>Realizar los aportes mensuales.(10%)</li>
          <li>Realizar las comprars requeridas para el desarrollo de mi rol.</li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Button onClick={() => {
        setStep(step + 1)
        setOpenModal(true)

      }} className="director margin-top">Aceptar Acuerdo de Honor</Button>

    </>
  )
}
