

import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { UserContext } from '../../../middleware/providers/user-context';
import { getListOfHostWithTheirSelectApplicants } from '../../../middleware/services/epaCoordinatorServices';
import { API, ROLES } from '../../../middleware/utils/constants';
import Paragraph from '../../atoms/paragraph';
import Checkbox from '../../atoms/checkbox';
import Button from '../../atoms/button';

const ActivitySeletedModal = ({ numPager, setNumPager, setStep, step }) => {
    const { user } = useContext(UserContext)
  
    const [state, setState] = useState({
        selected: [{ id: '', pdlConnection: false, status: true }],

    });
    const [selectedCandidate, setSelectedCandidate] = useState('');

    useEffect(() => {
        ListOfHostWithTheirSelectApplicants()
        setNumPager(0)
    }, [])
    const ListOfHostWithTheirSelectApplicants = async () => {
        const data = await getListOfHostWithTheirSelectApplicants(user?._id)

        setSelectedCandidate(data)
    };
    const onChangeCandidate = (e, idCandidate) => {
        selectedCandidate.forEach(item => {
            item.candidates.forEach(candidate => {
                if (candidate.hasOwnProperty('pdlConnection') && candidate?._id === idCandidate) {
                    candidate.pdlConnection = e; // Cambia a 'false' o cualquier valor deseado
                }
            });
        });


        setState((prevState) => {
            let newSelected = [...prevState.selected];
            // Si la opción seleccionada es vacía, eliminarla del estado si existe
            if (idCandidate === "" || idCandidate === null) {
                newSelected = newSelected.filter((item) => item.id !== idCandidate);
              

            } else {
                // Si existe, actualizamos el valor, si no, añadimos uno nuevo
                const existingIndex = newSelected.findIndex((item) => item.id === idCandidate && item.id !== '');
                if (existingIndex !== -1) {
                    newSelected[existingIndex] = { id: idCandidate, pdlConnection: e, status: true };
                } else {
                    newSelected.push({ id: idCandidate, pdlConnection: e, status: true });
                }
            }

            return { ...prevState, selected: newSelected };
        });

    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
          const data = {
            id: "",
            selected: state.selected.filter(candidate => candidate.id !== ""),
            action: 'SELECT_FINAL_EPA_CANDIDATES',
        };
        axios.post(`${API.SELECT_FINAL_EPA_CANDIDATES.url}/${user?._id}`, data).then((response) => {
            if (response.status === 201) {

                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };
  
    return (
        <>
            <span className="title">¡Ahora es tu turno de seleccionar!</span>
<br/>
            <Paragraph size="s" align="center" className="p-room-p-0">
                ¡Coordinador(a)!, De los PDLs de tus Aspirantes, ¿cuál está más conectado con tu PDL?
            </Paragraph>

            <div className="generation-map">
                <Paragraph size="s" align="center" >
                    <br/>
                    <div  >
                        <div className={`info `} >
                            <span className="role"> {ROLES[selectedCandidate[numPager]?.role]?.circle} </span>
                            <span className="name"><b>{selectedCandidate[numPager]?.name}</b></span>
                        </div>
                    </div>
                </Paragraph>
                <div className="epa-requirements__status">
                    {selectedCandidate[numPager]?.candidates?.map((candidate, index) => candidate.role == "candidate" && (
                        <span className={`medal-switch `}>
                            <Checkbox
                                className="medal-switch__info__name"

                                name="hold"
                                label={<>
                                    {candidate.name}
                                    <br />
                                    {ROLES[candidate.role || 'candidate'].circle}  {ROLES[candidate.role || 'candidate'].roleName}
                                </>}

                                checked={candidate?.pdlConnection}
                                onChange={(e) => onChangeCandidate(e.target.checked, candidate?._id)}

                            />
                        </span>
                    ))}

                </div>
            </div>

            <Button className="performance-button">Ver Desempeño</Button>
            <span className='title-small'>
              Este botón te muestra el<br /> desempeño de tus aspirantes

            </span>
  

            {
                numPager < 1 ? (<>
                    <Button onClick={() => setNumPager(numPager + 1)} className="margin-top" disabled={!selectedCandidate ? 'disable' : ''}>Continuar</Button>

                </>) : (<>
                    <Button onClick={onSubmitHandler} className="margin-top" disabled={!selectedCandidate ? 'disable' : ''}>Continuar</Button>

                </>)
            }       </>
    )
}
export default ActivitySeletedModal