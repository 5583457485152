import React, { useContext, useEffect, useState } from 'react'
import { API, ROLES } from '../../../middleware/utils/constants';
import Paragraph from '../../atoms/paragraph';
import Select from '../../atoms/select';
import Button from '../../atoms/button';
import axios from 'axios';
import { UserContext } from '../../../middleware/providers/user-context';

const NowItIsyourTurnToSelect = ({ setStep,step,applicantsAndHosts, className, icon }) => {
    const [shortlistedZero, setShortlistedZero] = useState(null);
    const [shortlistedOne, setShortlistedOne] = useState(null);
    const [flagNext, setflagNext] = useState(0);
    const { user } = useContext(UserContext)
    const [state, setState] = useState({
        selected: [{ id: '', index: 0, status: true }],

    });
    const sendSelection = (e) => {
        e.preventDefault()
        
        const data = {
            id: "",
            selected: state.selected.filter(candidate => candidate.id !== ""),
            action: 'SELECT_FINAL_EPA_CANDIDATES',
        };
        axios.post(`${API.SELECT_FINAL_EPA_CANDIDATES.url}/${user?._id}`, data).then((response) => {
            if (response.status === 201) {
         
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
       
    }
    const handleSelect = (candidateId, index) => {
        let selected = [...state.selected];
        selected[index] = { id: candidateId, index: index, status: true };
        setState({ selected });
    }
    useEffect(() => {
        applicantsAndHosts.map((item, index) => {

            axios.get(`${API.SHORTLISTED_APPLICANTS.url}/${item?._id}?action=SHORTLISTED_APPLICANTS`).then(response => {
                if (index === 0) {
                    setShortlistedZero(response.data.data)
                }
                if (index === 1) {
                    setShortlistedOne(response.data.data)
                }
            })
        })
    }, [applicantsAndHosts]);
    console.log(state)
    console.log(state.selected?.length)
    return (
        <>

            <form onSubmit={sendSelection}>
                {

                    applicantsAndHosts?.map((generation, index) => <div className="generation-map ">
                        <Paragraph size="xs" align="center" className={`${className}`} >

                            <div className={`info host`} >
                                <span className="name"><b>{generation.name}</b></span>
                            </div>
                            <div className={`info host`}>
                                <span className="role">{icon} {generation.role}</span>
                            </div>
                        </Paragraph>

                        <div className="epa-requirements__status">
                            {
                                index === 0 && (<>
                                    {
                                        <>

                                            <div className={`medal-switch select-candidate`}>
                                                <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value, 1)}>
                                                    <option value="">Selecciona tu Aspirantes</option>
                                                    {
                                                        shortlistedZero?.map((candidate) => (
                                                            <option value={candidate?.idUser} >{candidate.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                            <div className={`medal-switch select-candidate`}>
                                                <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value, 2)}>
                                                    <option value="">Selecciona tu Aspirantes</option>
                                                    {
                                                        shortlistedZero?.map((candidate) => (
                                                            <option value={candidate?.idUser} >{candidate.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </>

                                    }
                                </>)
                            }
                            {
                                index === 1 && (<>
                                    {
                                        <>
                                            <div className={`medal-switch select-candidate`}>
                                                <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value, 3)}>
                                                    <option value="">Selecciona tu Aspirantes</option>
                                                    {
                                                        shortlistedOne?.map((candidate) => (
                                                            <option value={candidate?.idUser} >{candidate.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                            <div className={`medal-switch select-candidate`}>
                                                <Select className="medal-switch__info__name" onChange={(e) => handleSelect(e.target.value, 4)}>
                                                    <option value="">Selecciona tu Aspirantes</option>
                                                    {
                                                        shortlistedOne?.map((candidate) => (
                                                            <option value={candidate?.idUser} >{candidate.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </>
                                    }
                                </>)
                            }
                        </div>

                    </div>)}


                {/* <Button onClick={() => setStep(step + 1)} className="margin-top" disabled={flagNext===0?'disabled':''}>Continuar</Button>
       */}
                <Button className="margin-left" disabled={state.selected?.length <5 ? true : false}>Continuar</Button>
            </form>

        </>
    )
}

export default NowItIsyourTurnToSelect;
