import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";

import NewCoordinator from "../../../resources/images/new-coordinator.png";
import GoodHandCaptain from '../../../resources/images/good-hand-captain.png';

import ClapHandCaptain from "../../../resources/images/clap-captain.png";
import Book from "../../../resources/images/book.png";
import TableNewDirectorAngry from "../../../resources/images/table_new_director_angry.png";
import ProfileTwobo from "../../../resources/images/profile-twobo.png";
import NewCaptain from "../../../resources/images/new_captain.png";
import CalendarCheckHost from "../../../resources/images/calendar-check-host.png";
import ScreenTools from "../../../resources/images/screen-tools.png";
import MoneyHand from "../../../resources/images/money-hand.png";
import ArrowSmall from "../../../resources/images/Arrow-small.png";
import NewHost from "../../../resources/images/new-host.png";
import NewDirector from "../../../resources/images/director-avatar.png";
import TableNewDirector from "../../../resources/images/table_new_director.png";
import ConfirmHostGif from "../../../resources/images/confirm-host-gif.png";
import Vector from "../../../resources/images/Vector.png";

import { API, ROLES } from "../../../middleware/utils/constants";
import Checkbox from "../../atoms/checkbox";
import { QueriesListNewRoles } from "../../../middleware/services/useQueryListNewRolesServices";
import { QueriesUsersBelowBydId } from "../../../middleware/services/useQueryUsersBelowCoordinatot";
import axios from "axios";
import './index.scss';
import { QueriesEpaDateCoordinatingSchedulesById } from "../../../middleware/services/useQueryEpaDateCoordinatingSchedules";
import { Toggle } from "../../atoms/switch";
import { QueriesGetInformation } from "../../../middleware/services/useQueryInformationService";
const circle = {
    host: '⚪',
    'new host': '⚪',
    coordinator: '🔴',
    'new coordinator': '🔴',
    director: '🟡',
    captain: '🔵',
}

const CaptainEpaEndDay = ({ finishChallenge, step, setStep, ...props }) => {

    const { challengeName, idChallenge } = useParams();
    const { user } = useContext(UserContext)
    
   
    const { data: EpaDateCoordinatingS } = QueriesEpaDateCoordinatingSchedulesById(user._id)
 
    const { data } = QueriesListNewRoles(user?._id);
    const{data:getInformation}=QueriesGetInformation(user?._id)
    console.log(getInformation)
    const [users, setUsers] = useState([]);

    const [foundationsData, setFoundationsData] = useState([]);

    const { data: UsersBelow } = QueriesUsersBelowBydId(user?._id)


    useEffect(() => {
        if (UsersBelow) {
            setUsers(UsersBelow);
            const fetchData = async () => {
                try {
                    const foundationPromises = UsersBelow.map(async (user) => {
                        const response = await axios.get(`${API.FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES.url}/${user._id}?action=foundations-selected-by-new-candidates`);
                       
                        return {
                            userId: user._id,
                            name: user.name,
                            foundations: response.data.data, // Asumimos que los datos de fundaciones están aquí
                        };
                    });

                    const allFoundationsData = await Promise.all(foundationPromises);
                    setFoundationsData(allFoundationsData);
                } catch (error) {
                    console.error("Error fetching foundation data:", error);
                }
            };

            fetchData();
        }
    }, [UsersBelow]);
    const [inputDate, setInputDate] = useState({
        "date": "",
        "hour": "",
        "status": true
    })
    const [modal, setModal] = useState(false)
    const [nameCoordinator, setNameCoordinator] = useState('')
    const [idCoordinator, setIdCoordinator] = useState('')
    const handlerAccept = (row, id, name) => {
        setIdCoordinator(id)
        setNameCoordinator(name)
        setInputDate({
            "date": row.date,
            "hour": row.hour,
            "status": true
        })

        setModal(true)
    }
    const handlerEdit = (row) => {
        //setscheduleParam(row)
        setStep(step + 3)
    }
    const [value, setValue] = useState(true)
    const [countToggle, setCountToggle] = useState(0)
    const [showCoordinator,setShowCoordinator]=useState(false)
    const [IdshowCoordinator,setIdShowCoordinator]=useState(false)
    const hadlerToggle = (id) => {
        console.log("id", id)
       // setSelectedToggle({ 'id': id, status: true, property: "" })
        setCountToggle(countToggle + 1)
        setValue(id)

    }
    const ShowCoordinatorHandler=(showCoordinator,id) => {
        setShowCoordinator(!showCoordinator)
        setIdShowCoordinator(id)
    }
console.log("foundationsData")
console.log(foundationsData)
    return (

        <>
            {challengeName === 'Desafío Honor' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <img src={NewCaptain} alt="new-host" width={209} height={209} />
                    <Paragraph size="s" align="center">
                        ¿¡Listos para hacer historia?! Hoy vas a sellar el compromiso con tu equipo porque aquí, en ACDC, todos compartimos un Legado en común, estamos construyendo el ecosistema de innovación y emprendimiento 4.0 de nuestra Comunidad Épica.
                    </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <div>
                        <img src={TableNewDirectorAngry} />
                        <Paragraph align="left" className="text-hungry" size="s">
                            “Las palabras de un líder y los pasos que da, deben coincidir. Como dice el dicho: “Tu palabra es tu valor y tu valor está en tu palabra.”
                        </Paragraph>
                    </div>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left" className="gift-text-phrase ">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <Paragraph align="left" className="p-room-p-1" size="s">
                        Es fundamental que cumplas con tu palabra no solo para lograr tus metas personales y tu PDL sino también para permitir que todo el equipo ACDC pueda realizar con éxito su PDL. De este compromiso de honor depende que:
                    </Paragraph>
                    <br />
                    <Paragraph size="s" align="left" className="p-room-p-1">
                        {data && data.map((item, index) => {
                            // Si el elemento es un array, lo iteramos y mostramos sus datos
                            if (Array.isArray(item)) {
                                return (

                                    <>

                                        {item?.map((subItem) => subItem.role !== "candidate" && (
                                            <>
                                                Tu {ROLES[subItem.role]?.circle}<b>{subItem.name}. </b><br />
                                                logres generar hasta USD 80.000 al mes.
                                                <br />
                                                <br />
                                            </>

                                        ))}

                                    </>
                                );
                            } else {
                                // Si el elemento no es un array, mostramos sus datos directamente
                                return (

                                    <>
                                        Tu {ROLES[item.role]?.circle}<b>{item.name} </b><br />
                                        logres generar hasta USD 80.000 al mes.
                                        <br />
                                        <br />
                                    </>

                                );
                            }
                        })}

                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <img src={MoneyHand} alt="Money Hand" className="money-hand" />
                    <Paragraph size="s" align="center" className="p-room-p-1">
                        En total, tu equipo ACDC tiene el potencial de generar USD 400 mil al mes. Por lo tanto, el aporte de tu equipo, correspondiente al 10% para el Ecosistema de Innovación y emprendimiento ACDC sería potencialmente de USD 40 mil mensuales, de los cuáles USD 4 mil mensuales irán a las Fundaciones que el equipo elija.
                    </Paragraph>

                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">
                        <b>Fundaciones elegidas por los(as)
                            <br />
                            Nuevos(a) Anfitriones(as)</b>

                    </span>
                    <br />
                    <Paragraph size="s" align="left" className="p-room-p-1">
                       Del aporte mensual al Ecosistema, el 10% se destinará a fundaciones. Los(as) Nuevos(as) Anfitriones(as) eligieron el tipo de fundación al que quieren aportar:
                    </Paragraph>

                    <br />
                    <div className="user-card-hungry">
                        <div className="user-header-hungry">
                            <img src={NewDirector} alt="Paula Inés" width={80} height={80} className="avatar-hambre" />
                            <div className="user-details-hungry">
                                <span className="user-name-hungry">{user?.name}</span>
                                <p className="user-role-hungry">Nuevo(a) Director(a)</p>
                            </div>
                        </div>

                    </div>
                    {users.map((user) => (
                        <>
                            <>
                                <div className="user-card-hungry">
                                    <div className="user-header-hungry">
                                        <img src={NewCoordinator} alt="Paula Inés" width={80} height={80} className="avatar-hambre" />
                                        <div className="user-details-hungry">
                                            <span className="user-name-hungry">{user.name}</span>
                                            <p className="user-role-hungry">Nuevo(a) Coordinador(a)</p>
                                        </div>
                                    </div>
                                    <div className="arrow-hungry" >
                                        <img src={ArrowSmall} className="arrow-small" onClick={()=>ShowCoordinatorHandler(showCoordinator,user._id)} />
                                    </div>

                                </div>

                            </>

                            {foundationsData
                                .filter((foundation) => foundation.userId === user._id)
                                .map((foundation) => (
                                    foundation.foundations.map((fnd, index) => (
                                        <>
                                          {
                                            showCoordinator === true && IdshowCoordinator===user._id?(
                                                <>
                                                 <div className="user-card-h host">
                                                <div className="user-header-h">
                                                    <img src={NewHost} alt="Luis David" width={80} height={80} className="avatar-h" />
                                                    <div className="user-info-h">
                                                        <span className="user-name-h">{fnd.name}</span>
                                                        <p className="user-role-h">Nuevo(a) Anfitrión(a)</p>
                                                    </div>
                                                </div>




                                                {fnd.fundation?.[0]?.options?.map((option) => (
                                                    <>

                                                        <div className='honor-box-checkbox'>
                                                            <Checkbox label={option.option} checked={true} className="option-checkbox-honor" />
                                                        </div>
                                                    </>

                                                ))}

                                            </div>
                                            <br />
                                                </>
                                            ):(<></>)
                                          }
                                           
                                           
                                        </>
                                    ))
                                ))}
                        </>
                    ))}
                    <br />

                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-day-container">

                <div className="card-host card-host-border cap-new-card" type="secondary" outline>
                        <span className="title-honor">Acuerdo de Honor<br /> </span>
                        <span className="title-honor"> como nuevo(a) Coordinador(a)</span>
                        <br />
                        <br />
                        <Paragraph size="s" align="center" className="">
                            Reconozco que cada acción que tomo no solo afecta mis metas, sino que también contribuye al logro de las metas de mi equipo ACDC. Así, me comprometo a:
                        </Paragraph>
                        <br />
                        <ul>
                            <li>Cumplir con las misiones diarias del cronograma.</li>
                            <li>Mantener comunicación transparente y colaborativa.</li>
                            <li>Partocopar en las fechas y horarios establecidos de los EPAs.</li>
                            <li>Mantener y mejorar mi Desempeño.</li>
                            <li>Realizar los aportes mensuales.(10%)</li>
                            <li>Realizar las comprars requeridas para el desarrollo de mi rol.</li>
                        </ul>
                    </div>
                    <br />
                    <br />
                    <Button onClick={() => setStep(step + 1)}>Acepto Acuerdo de Honor</Button>
                </Step>

                <Modal isOpen={step === 7} className="confirmation-register-modal">

                    <div className="confirmation-register-container">
                        <img src={GoodHandCaptain} alt="good hand" />
                        <Paragraph align="center" size="s">
                            <b>¡Excelente!</b>
                            <br />
                            <br />
                            Ahora que ya hiciste el Acuerdo de honor, continúa con el video para realizar las actividades de la siguiente pausa.
                            <br />
                            <br />

                        </Paragraph>

                        <Button size="small" className="marginTop" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                        <br/>
                    </div>
                </Modal>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <div>
                        <img src={TableNewDirector} />

                        <Paragraph align="left" className="honor-text-table" size="s">

                            “El honor está conformado por las virtudes de la integridad, la honestidad, el sacrificio, la lealtad y la humildad, pero de un corazón justo y piadoso para aquellos menos poderosos”.

                        </Paragraph>
                    </div>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph className="gift-text-phrase" size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>

                    <Button   >Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>

                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title fontWeight">Confirmación de fecha para los EPAs</span>
                    <Paragraph size="s" align="center" className="p-size">
                        aaaA continuación podrás ver la fecha y hora del EPA de cada uno de los(As) Nuevos(as) Coordinadores(as) de tu Nuevo(a) Director(a):
                        <br />
                        <br />
                    </Paragraph>
                    <div className="card-host-captain">
                        <div className="card-host-header">
                            <img src={NewDirector} alt="new-host" width={80} height={80} />
                            <div className="coordinator-info">
                                <p className="coordinator-name">{user?.name}</p>
                                Nuevo(a) Director(a)
                            </div>
                        </div>
                    </div>
                    {
                        EpaDateCoordinatingS?.map((item, index) => (
                            <div className="card-host-captain">
                                <div className="card-host-header">
                                    <img src={NewCoordinator} alt="new-host" width={80} height={80} />
                                    <div className="coordinator-info">
                                        <p className="coordinator-name">{item.nameCoordinator}</p>
                                        <span className="status nuevo">{circle.coordinator} Nuevo(a) Coordinador(a)</span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <input type="text" className='epa-hungry-date' value={item.schedule[index]?.date} />
                                    <input type="text" className='epa-hungry-hour' value={item.schedule[index]?.hour} />
                                </div>

                            </div>

                        ))
                    }
                    <Button onClick={() => setStep(step + 1)} >Aceptar</Button>

                </Step>
                <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío Honor.</b> del Final del EPA
                    </Paragraph>
                    <img src={ClapHandCaptain} alt="Clap Hand" className="good-hands" />
                    <Paragraph size="s" align="center" className="card-host-captain ">
                        Ya puedes iniciar el  <b>Desafío Humildad.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button  >Ver video</Button>


                    <Button size="small" style="ghost" onClick={() => finishChallenge(null)}>Finalizar</Button>

                </Step>
            </>
            }

            {/*new capitan humiildad*/}
            {challengeName === 'Desafío Humildad' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <img src={CalendarCheckHost} alt="new-host" width={209} height={209} />
                    <Paragraph size="s" align="center" className="p-room-p-0">
                        Ahora que ya se definió la fecha y hora del EPA, continúa en la Meet para realizar las actividades de las siguientes pausas del video.
                        <br />
                        <br />
                    </Paragraph>
                    <Paragraph size="s" align="center" className="p-room-p-0">
                        En este momento se están creando los grupos de WhatsApp correspondientes.
                    </Paragraph>
                    <br />
                    <Button
                        size="small"
                        style="ghost"
                    >
                        Ver video
                    </Button>
                    <Paragraph size="s" align="center" className="card-host-captain">
                        Podrás continuar cuando el Nuevo(a) Director(a) confirme que se han agregado los dos Nuevos Anfitriones al grupo de WhatsApp.
                    </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>

                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <div>
                        <img src={TableNewDirector} />

                        <Paragraph align="left" className="honor-text-table" size="s">

                            “No existen secretos para alcanzar el éxito. Este es el resultado de la preparación, el trabajo arduo y de aprender de los fracasos”.

                        </Paragraph>
                    </div>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph className="gift-text-phrase" size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>

                    <Button   >Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>

                </Step>

                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <span className="title">Confirmación Compras y obsequios</span>
                    <div className="avatar-host">
                        <img src={ConfirmHostGif} className="avatar-host-img" />
                    </div>

                    <span className="title-gift-span">¡Nuevo(a) Capitán(a)!</span>
                    <Paragraph align="center" size="s" className="p-size">

                        En este momento los dos Nuevos Anfitriones se deben unir al canal de YouTube de ACDC con el obsequio 🎁 que recibirán por parte de los Nuevos(as) Coordinadores(as).
                    </Paragraph>
                    <Paragraph align="center" size="s" >

                        En la siguiente pantalla podrás visualizar que se dieron los obsequios y quienes ya se unieron al canal de Youtube.
                    </Paragraph>
                    <br />
                    <Button size="small" style="ghost">Manual de compra</Button>
                    <Button className="btn-gift-2" onClick={() => setStep(step + 1)}>Continuar</Button>

                </Step>

                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                 
                    <div className="user-list-checkbox">
                        <div className="user-card-checkbox">
                            <div className="card-host-header">
                                <img src={NewDirector} alt="new-host" width={80} height={80} />
                                <div className="coordinator-info">
                                    <p className="coordinator-name">{user?.name}</p>
                                    Nuevo(a) Director(a)
                                </div>
                            </div>
                        </div>
             
                        {users.map((user) => (
                            <>
                                <>
                                    <div className="user-card-checkbox">
                                        <div className="user-info-checkbox">
                                            <img src={NewCoordinator} alt="new-host" width={80} height={80} className="user-avatar-checkbox" />
                                            <div className="user-details-checkbox">
                                                <span className="user-name-checkbox">{user.name}</span>
                                                <p className="user-action-checkbox">Dió los obsequios <img src={Vector} className="action-icon-checkbox" /><img src={Vector} className="action-icon-checkbox" /></p>
                                            </div>
                                        </div>
                                        <div className="user-status-checkbox-2">
                                            <div className="status-check-checkbox-3">
                                                &#10004;
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrow-hungry" style={{'display':'flex','justify-content':'center', 'margin-top':'-24px','margin-bottom':'22px'}}>
                                        <img src={ArrowSmall} className="arrow-small" onClick={()=>ShowCoordinatorHandler(showCoordinator,user._id)} />
                                    </div>
                      
                                </>

                                {foundationsData
                                    .filter((foundation) => foundation.userId === user._id)
                                    .map((foundation) => (
                                        foundation.foundations.map((fnd, index) => (
                                            <>
                                             {
                                            showCoordinator === true && IdshowCoordinator===user._id?(<>
                                               <div className="user-card-checkbox">
                                                    <div className="user-info-checkbox">
                                                        <img src={NewHost} alt="new-host" width={80} height={80} className="user-avatar-checkbox" />
                                                        <div className="user-details-checkbox">
                                                            <span className="user-name-checkbox">{fnd.name}</span>
                                                            <p className="user-action-checkbox-2">Se unió al Canal de Youtube</p>
                                                        </div>
                                                    </div>
                                                    <div className="user-status-checkbox-2">
                                                        <div className="status-check-checkbox-3">
                                                            &#10004;
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                            </>):(<></>)
                                            
                                            }
                                                
                                             
                                            </>
                                        ))
                                    ))}
                            </>
                        ))}
                        
                    </div>
                    <br />
                    <Button className="btn-gift-2" onClick={() => setStep(step + 1)}>Continuar EPA</Button>

                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío Humildad.</b> del Final del EPA
                    </Paragraph>
                    <img src={ClapHandCaptain} alt="Clap Hand" className="good-hands" />
                    <Paragraph size="s" align="center" className="card-host-captain">
                        Ya puedes iniciar el  <b>Desafío Hambre.</b>
                        <br />
                        <br />
                        <b> 👋🏻 Ya nos vemos.</b>

                    </Paragraph>

                    <Button onClick={() => finishChallenge(null)} >Finalizar</Button>


                </Step>
            </>
            }


            {challengeName === 'Desafío Hambre' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <div>
                        <img src={TableNewDirectorAngry} />
                        <Paragraph align="left" className="text-hungry" size="s">
                            “El líder no solo tiene éxito por sí mismo y no solo tiene éxito al formar a otros líderes, sino que consigue formar líderes que a su vez tienen la capacidad de formar a otros, quienes de ahí en adelante continuarán con el Legado.”
                        </Paragraph>
                    </div>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left" className="gift-text-phrase ">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <span className="title">!Momento de los agradecimientos!</span>
                    <img src={ScreenTools} alt="new-host" width={209} height={209} />
                    <br />
                    <br />
                    <Paragraph size="s" align="center" className="p-room-p-0">
                        💡 Nuevo(a) Capitán(a), es momento que los Nuevos Anfitriones comiencen a agradecer a sus Validadores. Mañana en el Día de Inducción, iniciarán la Convocatoria. Requerirán el link de registro al EPA, tu Director(a) será el encargado de generarlo.             <br />
                        <br />
                    </Paragraph>

                    <br />
                    <Button
                        size="small"
                        style="ghost"
                    >
                        Ver video
                    </Button>

                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <div className="user-list-checkbox">
                        <div className="user-card-checkbox">
                            <div className="card-host-header">
                                <img src={NewDirector} alt="new-host" width={80} height={80} />
                                <div className="coordinator-info">
                                    <p className="coordinator-name">{user.name}</p>
                                    Nuevo(a) Director(a)
                                </div>
                            </div>

                        </div>

                
                    {users.map((user) => (
                        <>
                            <>
                            <div className="user-card-hungry">
                            <div className="user-header-hungry">
                                <img src={NewCoordinator} alt="Paula Inés" width={80} height={80} className="avatar-hambre" />
                                <div className="user-details-hungry">
                                    <span className="user-name-hungry">{user.name}</span>
                                    <p className="user-role-hungry">Nuevo(a) Coordinador(a)</p>
                                </div>
                            </div>
                            <div className="arrow-hungry">
                                <img src={ArrowSmall} className="arrow-small" onClick={()=>ShowCoordinatorHandler(showCoordinator,user._id)} />
                            </div>
                        </div>
                        <div className="user-card-hungry">
                            EPA:
                            <div className="card-body">
                                <input type="text" className='epa-hungry-date' value='15 de Nov. del 2024' />
                                <input type="text" className='epa-hungry-hour' value='7:00 AM' />
                            </div>
                        </div>
                            </>

                            {foundationsData
                                .filter((foundation) => foundation.userId === user._id)
                                .map((foundation) => (
                                    foundation.foundations.map((fnd, index) => (
                                        <>
                                        {
                                            showCoordinator === true && IdshowCoordinator===user._id?(
                                                <>
                                                        
                                        <div className="card-cap-new">
                                <div className="card-header-cap-new">

                                    <img src={NewHost} alt="new-host" width={80} height={80} />

                                    <div className="user-info-cap-new">
                                        <h3 className="name-cap-new">{fnd.name}</h3>
                                        <p className="role-cap-new">Nuevo(a) Anfitrión(a)</p>
                                    </div>
                                </div>
                                <div className="card-body-cap-new">
                                    <div className="info-row-cap-new">
                                        <span className="span-cap-new">Entrenamiento completado</span>
                                        <span className="check-cap-new">✔️</span>
                                    </div>
                                    <div className="info-row-cap-new">
                                        <span className="span-cap-new">Agradecimientos Realizados:</span>
                                        <span className="check-cap-new">{user?.gratitudeDoneCount||0}</span>
                                    </div>

                                </div>
                                <Button size="small">Ver validadores</Button>
                            </div>
                                            <br />
                                                </>
                                            ):(<></>)
                                          }
                                  

                                         
                                        </>
                                    ))
                                ))}
                        </>
                    ))}
                        </div>
                    <br />
                    <br />
                    <Button className="btn-gift-2" onClick={() => setStep(step + 1)}>Siguiente</Button>
                </Step>
                <Modal isOpen={step === 4} className="confirmation-register-modal">


                    <div className="validator-list-cap">
                    {
                         getInformation?.personalReferences?.map((row,index)=>(
                            <div className="validator-item-cap" onClick={() => setStep(step + 1)}>
                                <div className="checkbox-cap"></div>
                                <span className="validator-name-cap">{row.name} #{index+1}</span>
                            </div>
                        ))
                    }
                    </div>
                </Modal>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        Ahora dirígete a tu WhatsApp y cambia la foto de perfil por la de TWOBOT.
                        <br />
                        <br />
                    </Paragraph>
                    <div className="profile-card-cap">
                        <div className="profile-header-cap">
                            <img src={ProfileTwobo} alt="TWOBOT" className="profile-pic-cap" />

                        </div>

                        <div className="confirmation-section-cap">
                            <p>Ya tengo la foto de perfil del TWOBOT.</p>
                            {/* <label className="switch-cap">
                                <input type="checkbox-cap" />
                                <span className="slider-cap round-cap"></span>
                            </label> */}
                            <div className="toggle-switch">
                                <Toggle value={value} className="switch-input" onClick={() => hadlerToggle(!value)} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />

                    <Button className="btn-gift-2" onClick={() => setStep(step + 1)} disabled={value}>Continuar</Button>

                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-day-container">
                    <br />
                    <Paragraph size="s" align="center">
                        <b>¡Felicitaciones!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        ¡Has completado los desafíos del EPA! Mañana es el día de Inducción del siguiente nivel de influencia, Formación de Líderes que forman líderes!
                    </Paragraph>
                    <img src={ClapHandCaptain} alt="Clap Hand" className="good-hand" />


                    <Button onClick={() => finishChallenge(null)} >Finalizar</Button>

                </Step>
            </>
            }
        </>
    )
}

export default CaptainEpaEndDay;