import React, { useContext, useEffect, useState } from 'react'

import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'
import Book from "../../../resources/images/book.png";
import Card from '../../atoms/card';
import './index.scss';
import { API } from '../../../middleware/utils/constants';
import { UserContext } from '../../../middleware/providers/user-context';
import NewCoordinator from "../../../resources/images/new-coordinator.png";
import ArrowSmall from "../../../resources/images/Arrow-small.png";
import NewHost from "../../../resources/images/new-host.png";
import axios from 'axios';
import Checkbox from '../../atoms/checkbox';
import {QueriesUsersBelowBydId } from '../../../middleware/services/useQueryUsersBelowCoordinatot';


export const ChallengeHonorFive = ({ setStep, step }) => {
  const { user } = useContext(UserContext)

  const [users, setUsers] = useState([]);
  
  const [foundationsData, setFoundationsData] = useState([]);

  const { data } = QueriesUsersBelowBydId(user?._id)
  //id_codidnador

  useEffect(() => {
    if (data) {
      setUsers(data);
      const fetchData = async () => {
        try {
          const foundationPromises = data.map(async (user) => {
            const response = await axios.get(`${API.FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES.url}/${user._id}?action=foundations-selected-by-new-candidates`);
            return {
              userId: user._id,
              name: user.name,
              foundations: response.data.data, // Asumimos que los datos de fundaciones están aquí
            };
          });

          const allFoundationsData = await Promise.all(foundationPromises);
          setFoundationsData(allFoundationsData);
        } catch (error) {
          console.error("Error fetching foundation data:", error);
        }
      };

      fetchData();
    }
  }, [data]);

  return (
    <>
      <div className="container">
        <span className="title">Fundaciones elegidas por los(as)
          <br />
          Nuevos(a) Anfitriones(as)</span>
        <Paragraph size="s" align="justify" className="text-honor">
          Del aporte mensual al Ecosistema, el 10% se destinará a fundaciones. Los(as) Nuevos(as) Anfitriones(as) eligieron el tipo de fundación al que quieren aportar:
        </Paragraph>
        <br />
        <br />
        <br />

  
        {users.filter(item=>item.role==="new coordinator").map((user) => (
          <>
            <>
              <div className="user-card-hungry">
                <div className="user-header-hungry">
                  <img src={NewCoordinator} alt="Paula Inés" width={80} height={80} className="avatar-hambre" />
                  <div className="user-details-hungry">
                    <span className="user-name-hungry">{user?.name}</span>
                    <p className="user-role-hungry">Nuevo(a) Coordinador(a)</p>
                  </div>
                </div>
                <div className="arrow-hungry">
                  <img src={ArrowSmall} className="arrow-small" />
                </div>

              </div>

            </>

            {foundationsData
              .filter((foundation) => foundation.userId === user._id)
              .map((foundation) => (
                foundation.foundations.map((fnd, index) => (
                  <>


                    <div className="user-card-h host">
                      <div className="user-header-h">
                        <img src={NewHost} alt="Luis David" width={80} height={80} className="avatar-h" />
                        <div className="user-info-h">
                          <span className="user-name-h">{fnd.name}</span>
                          <p className="user-role-h">Nuevo(a) Anfitrión(a)</p>
                        </div>
                      </div>

                      {fnd.fundation?.[0]?.options?.map((option) => (
                        <>
                        <div className='honor-box-checkbox'>
                        <Checkbox  checked={true} className="option-checkbox-honor" />
                        <span className='honor-option-text'>{option.option}</span>  
                        </div>
                        
                        
                        </>
                        
                        ))}

                    </div>
                    <br/>
                  </>
                ))
              ))}
          </>
        ))}
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <Button onClick={() => setStep(step + 1)}>Continuar</Button>
    </>
  )
}
